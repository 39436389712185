import React, { useState, useEffect, useRef } from "react";
import moment from 'moment';
import MomentConfig from '../../../common/MomentConfig';
import { Card, CardBody, Row, Button } from "reactstrap";
import VitalsDeleteModal from "./VitalsDeleteModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const VitalsTable = (props) => {
  const [sliderCount, setSliderCount] = useState(6); // eslint-disable-line

  const sliderWrapRef = useRef(null);
  const sliderCalenRef = useRef(null); // eslint-disable-line
  const sliderEleRef = useRef(null); // eslint-disable-line


  const vitalsTableRef = useRef();
  const vitalsTableViewportRef = useRef();
  const [sliderButtons, setSliderButtons] = useState(false);


  const positionDisplay = (position_id) => {
    let positionString = "";
    if (position_id == 1) { // eslint-disable-line
      positionString = "Arm";
    } else if (position_id == 2) { // eslint-disable-line
      positionString = "Chest";
    } else if (position_id == 3) { // eslint-disable-line
      positionString = "Head";
    }

    return positionString;
  };

  const [slideConfig] = useState({
    totalSlide: 0,
    sliderWidth: 0,
    sliderEachSlideWidth: 165,
    count: 0
  })

  const expandRow = { // eslint-disable-line
    renderer: (row) => (
      <div className="VitalsTableExpands">
        <span>{`Height(cm) :  ${row.Centimeter !== "" ? row.Centimeter : "---"}`}</span>
        <span>{`Respiration :  ${row.respiration !== "" ? row.respiration : "---"}`}</span>
        <span>{`Pulse :  ${row.pulseRate !== "" ? row.pulseRate : "---"}`}</span>
        <span>{`Peak Flow :  ${row.peakFlow !== "" ? row.peakFlow : "---"}`}</span>
        <span>{`Heart Rate :  ${row.heartRate !== "" ? row.heartRate : "---"}`}</span>
        <span>{`Grip Strength :  ${row.gripStrength !== "" ? row.gripStrength : "---"}`}</span>
        <span>{`Position :  ${row.position !== "" ? positionDisplay(row.position) : "---"}`}</span>
        <span>{`Pain Scale :  ${row.painScale !== "" ? row.painScale : "---"}`}</span>
        <span>{`o2 Saturation :  ${row.o2Saturation !== "" ? row.o2Saturation : "---"}`}</span>
        <span>{`Vision :  ${row.vision !== "" ? row.vision : "---"}`}</span>
        <span>{`BMI :  ${row.BMI !== "" ? row.BMI : "---"}`}</span>
        <span>{`BSA :  ${row.BSA !== "" ? row.BSA : "---"}`}</span>
        <span>{`Glucose :  ${row.glucose !== "" ? row.glucose : "---"}`}</span>
        <span>{`Comments :  ${row.comment !== "" ? row.comment : "---"}`}</span>
      </div>
    ),
  };

  const getWindowWidth = () => {
    const getWidth = window.innerWidth;
    if (getWidth <= 1300 && getWidth > 1000) {
      setSliderCount(4);
    } else if (getWidth <= 1000 && getWidth > 800) {
      setSliderCount(3);
    } else if (getWidth <= 800 && getWidth > 600) {
      setSliderCount(2);
    } else if (getWidth <= 600) {
      setSliderCount(1);
    }
  };

  const getVitalsTableWidth = () => {
    if (vitalsTableRef.current && vitalsTableViewportRef.current) {
      if (vitalsTableRef.current.offsetWidth <= vitalsTableViewportRef.current.offsetWidth) {
        setSliderButtons(false)
      } else {
        setSliderButtons(true)
      }
    }

  }

  const onPrevNextHandler = (scrollOffset) => {
    if (vitalsTableViewportRef.current) {
      vitalsTableViewportRef.current.scrollLeft += scrollOffset;
    }
  }

  useEffect(() => {
    if (sliderWrapRef.current) {
      slideConfig.sliderWidth = sliderWrapRef.current.offsetWidth;
      slideConfig.totalSlide = document.getElementsByClassName('pat-time-slot').length;
      let totalWidth = slideConfig.sliderWidth * slideConfig.totalSlide;
      document.getElementsByClassName('pat-vital-row')[0].style.width = totalWidth / 2 + 'px';
      let collection = document.getElementsByClassName('pat-vital-row');
      for (let item of collection) {
        item.style.width = totalWidth + 'px';
      }
    }
    getWindowWidth();
    getVitalsTableWidth();
    const handleResize = () => {
      getWindowWidth();
      getVitalsTableWidth();
    }
    try {
      window.removeEventListener('resize', handleResize)
      window.addEventListener('resize', handleResize)
    } catch (e) { }
  },);

  const onNextHandler = () => { // eslint-disable-line
    let collection = document.getElementsByClassName('rightButton');
    for (let item of collection) {
      item.click();
    }
  }

  const onPrevHandler = () => { // eslint-disable-line
    let collection = document.getElementsByClassName('leftButton');
    for (let item of collection) {
      item.click();
    }
  }

  const Dateformatter = (cell) => {
    return moment(cell).format(MomentConfig.MOMENT_FORMAT_DATE);
  };

  const Timeformatter = (cell) => { // eslint-disable-line
    return cell.split(' ')[1];
  };


  const TableActions = (cell, row) => {
    return (
      <>
        {/* <span className="badge badge-success">View </span> */}
        <VitalsDeleteModal
          TableRenderVitals={props.TableRenderVitals}
          id={row.id}
          rowData={row}
        />{" "}
      </>
    );
  };
  const defaultSorted = [ // eslint-disable-line
    {
      dataField: "recordDate",
      order: "desc",
    },
  ];

  const Emptyformatter = (cell) => {
    return (
      cell !== "" && cell !== "0" ? cell : "---"
    )
  }

  const tableColumns = [ // eslint-disable-line
    {
      dataField: "recordDate",
      text: "Date",
      sort: true,
      formatter: Dateformatter,
    },
    {
      dataField: "Kg",
      text: "Weight(Kg)",
      formatter: Emptyformatter,
    },
    {
      dataField: "temperature",
      text: "Temperature(C)",
      formatter: Emptyformatter,
    },
    {
      dataField: "bpSystolic",
      text: "BP-Systolic",
      formatter: Emptyformatter,
    },
    {
      dataField: "bpDiastolic",
      text: "BP-Diastolic",
      formatter: Emptyformatter,
    },
    {
      dataField: "",
      text: "Actions",
      formatter: TableActions,
    },
  ];



  useEffect(() => {
    props.TableRenderVitals();
  }, []); // eslint-disable-line


  return (
    <div className="VitalsTable">
      <Card>
        <CardBody className="health-card vital-table">
          {/* {props.VitalsNoDataFound ? (
            <p>No Data Found</p>
          ) : (
            <BootstrapTable
              keyField="id"
              data={props.data}
              columns={tableColumns}
              bordered={false}
              bootstrap4
              expandRow={expandRow}
              defaultSorted={defaultSorted}
            />
          )} */}
          {props.VitalsNoDataFound ? (
            <p>No Data Found</p>
          ) : (
            <>
              {props.vitalsLoaded ? (
                <>

                  {sliderButtons ? (
                    <Row className="justify-flex-end">
                      <Button
                        color="secondary"
                        className="vitals-table-scroller mr-2"
                        outline
                        onClick={() => onPrevNextHandler(-50)}
                      >
                        <FontAwesomeIcon
                          icon={faChevronLeft}
                          fixedWidth
                          className="align-middle"
                        />
                      </Button>
                      <Button
                        color="secondary"
                        className="vitals-table-scroller"
                        outline
                        onClick={() => onPrevNextHandler(50)}
                      >
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          fixedWidth
                          className="align-middle"
                        />
                      </Button>
                    </Row>
                  ) : null
                  }
                  <Row>
                    <div className="vitals-grid-container" ref={vitalsTableViewportRef}>

                      <div className="vitals-grid" ref={vitalsTableRef}>

                        <div className="vitals-grid-col vitals-grid-col--fixed-left">

                          <div className="vitals-grid-item vitals-grid-item--header">
                            <p></p>
                          </div>
                          <div className="vitals-grid-item">
                            <p className="pat-time-slot__item-vital">Weight (lbs / kg)</p>
                          </div>
                          <div className="vitals-grid-item">
                            <p className="pat-time-slot__item-vital">Height (ft / cm)</p>
                          </div>
                          <div className="vitals-grid-item">
                            <p className="pat-time-slot__item-vital">Temperature (F / C)</p>
                          </div>
                          <div className="vitals-grid-item">
                            <p className="pat-time-slot__item-vital">BP-Systolic</p>
                          </div>
                          <div className="vitals-grid-item">
                            <p className="pat-time-slot__item-vital">BP-Diastolic</p>
                          </div>
                          <div className="vitals-grid-item">
                            <p className="pat-time-slot__item-vital">Heart Rate</p>
                          </div>
                          <div className="vitals-grid-item">
                            <p className="pat-time-slot__item-vital">BMI</p>
                          </div>
                          <div className="vitals-grid-item">
                            <p className="pat-time-slot__item-vital">BSA</p>
                          </div>
                          <div className="vitals-grid-item">
                            <p className="pat-time-slot__item-vital">Pulse Rate</p>
                          </div>
                          <div className="vitals-grid-item">
                            <p className="pat-time-slot__item-vital">Respiration</p>
                          </div>
                          <div className="vitals-grid-item">
                            <p className="pat-time-slot__item-vital">Peak Flow</p>
                          </div>
                          <div className="vitals-grid-item">
                            <p className="pat-time-slot__item-vital">Grip Strength </p>
                          </div>
                          <div className="vitals-grid-item">
                            <p className="pat-time-slot__item-vital">Position</p>
                          </div>
                          <div className="vitals-grid-item">
                            <p className="pat-time-slot__item-vital">Pain Scale</p>
                          </div>
                          <div className="vitals-grid-item">
                            <p className="pat-time-slot__item-vital">O2 Saturation</p>
                          </div>
                          <div className="vitals-grid-item">
                            <p className="pat-time-slot__item-vital">Vision</p>
                          </div>
                          <div className="vitals-grid-item">
                            <p className="pat-time-slot__item-vital">Glucose</p>
                          </div>
                          <div className="vitals-grid-item">
                            <p className="pat-time-slot__item-vital">Comments</p>
                          </div>
                        </div>

                        {props.data.map(dayCol => {
                          return (
                            <div className="vitals-grid-col" key={dayCol.id}>
                              <div className="vitals-grid-item vitals-grid-item--header">
                                <div key={dayCol.recordDate} className="day-slot normal-text">
                                  <span>{Dateformatter(dayCol.recordDate)}</span>
                                  <span className="slot-date">{TableActions(dayCol, dayCol)}</span>
                                </div>
                              </div>
                              <div className="vitals-grid-item">
                                <ReactTooltip className="card-tooltip" />
                                <p className="pat-time-slot__items">
                                  {dayCol.Kg.length > 12 ? (
                                    <span className="pat-time-slot__item-vital-data" data-tip={dayCol.Kg}>{dayCol.Kg.substr(0, 12) + "..."}</span>
                                  ) : (
                                    <span className="pat-time-slot__item-vital-data">{dayCol.Pound != 0 ? dayCol.Pound : "--"} / {dayCol.Kg ? dayCol.Kg : "--"}</span>
                                  )}
                                </p>
                              </div>
                              <div className="vitals-grid-item">
                                <ReactTooltip className="card-tooltip" />
                                <p className="pat-time-slot__items">
                                  {dayCol.Centimeter.length > 12 ? (
                                    <span className="pat-time-slot__item-vital-data" data-tip={dayCol.Centimeter}>{dayCol.Centimeter.substr(0, 12) + "..."}</span>
                                  ) : (
                                    // <span className="pat-time-slot__item-vital-data">{(dayCol.Centimeter * 0.0328084 ).toFixed(1)  || "--"} / { dayCol.Centimeter || "--"}</span>
                                    <span className="pat-time-slot__item-vital-data">{dayCol.Feet && dayCol.Inches ? `${(dayCol.Centimeter * 0.0328084).toFixed(1) || "--"}` : (dayCol.Feet ? `${dayCol.Feet}` : "--")} {" / "} {dayCol.Centimeter || "--"}</span>

                                  )}
                                </p>
                              </div>
                              <div className="vitals-grid-item">
                                <ReactTooltip className="card-tooltip" />
                                <p className="pat-time-slot__items">
                                  {dayCol.temperature.length > 12 ? (
                                    <span className="pat-time-slot__item-vital-data" data-tip={dayCol.temperature}>{dayCol.temperature.substr(0, 12) + "..."}</span>
                                  ) : (
                                    <span className="pat-time-slot__item-vital-data">{dayCol.temperature ? ((dayCol.temperature * 9) / 5 + 32).toFixed(1) : "--"} / {dayCol.temperature || "--"}</span>
                                  )}
                                </p>
                              </div>
                              <div className="vitals-grid-item">
                                <ReactTooltip className="card-tooltip" />
                                <p className="pat-time-slot__items">
                                  {dayCol.bpSystolic.length > 12 ? (
                                    <span className="pat-time-slot__item-vital-data" data-tip={dayCol.bpSystolic}>{dayCol.bpSystolic.substr(0, 12) + "..."}</span>
                                  ) : (
                                    <span className="pat-time-slot__item-vital-data">{dayCol.bpSystolic || "--"}</span>
                                  )}
                                </p>
                              </div>
                              <div className="vitals-grid-item">
                                <ReactTooltip className="card-tooltip" />
                                <p className="pat-time-slot__items">
                                  {dayCol.bpDiastolic.length > 12 ? (
                                    <span className="pat-time-slot__item-vital-data" data-tip={dayCol.bpDiastolic}>{dayCol.bpDiastolic.substr(0, 12) + "..."}</span>
                                  ) : (
                                    <span className="pat-time-slot__item-vital-data">{dayCol.bpDiastolic || "--"}</span>
                                  )}
                                </p>
                              </div>
                              <div className="vitals-grid-item">
                                <ReactTooltip className="card-tooltip" />
                                <p className="pat-time-slot__items">
                                  {dayCol.heartRate.length > 12 ? (
                                    <span className="pat-time-slot__item-vital-data" data-tip={dayCol.heartRate}>{dayCol.heartRate.substr(0, 12) + "..."}</span>
                                  ) : (
                                    <span className="pat-time-slot__item-vital-data">{dayCol.heartRate || "--"}</span>
                                  )}
                                </p>
                              </div>
                              <div className="vitals-grid-item">
                                <ReactTooltip className="card-tooltip" />
                                <p className="pat-time-slot__items">
                                  {dayCol.BMI.length > 12 ? (
                                    <span className="pat-time-slot__item-vital-data" data-tip={dayCol.BMI}>{dayCol.BMI.substr(0, 12) + "..."}</span>
                                  ) : (
                                    <span className="pat-time-slot__item-vital-data">{dayCol.Kg && dayCol.Centimeter ? (dayCol.Kg / ((dayCol.Centimeter / 100) * (dayCol.Centimeter / 100))).toFixed(1) : "--"}</span>
                                  )}
                                </p>
                              </div>
                              <div className="vitals-grid-item">
                                <ReactTooltip className="card-tooltip" />
                                <p className="pat-time-slot__items">
                                  {dayCol.BSA.length > 12 ? (
                                    <span className="pat-time-slot__item-vital-data" data-tip={dayCol.BSA}>{dayCol.BSA.substr(0, 12) + "..."}</span>
                                  ) : (
                                    <span className="pat-time-slot__item-vital-data">{dayCol.Kg && dayCol.Centimeter ? (Math.pow(dayCol.Kg, 0.5378) * Math.pow(dayCol.Centimeter, 0.3964) * 0.024265).toFixed(1) : "--"}</span>
                                  )}
                                </p>
                              </div>
                              <div className="vitals-grid-item">
                                <ReactTooltip className="card-tooltip" />
                                <p className="pat-time-slot__items">
                                  {dayCol.pulseRate.length > 12 ? (
                                    <span className="pat-time-slot__item-vital-data" data-tip={dayCol.pulseRate}>{dayCol.pulseRate.substr(0, 12) + "..."}</span>
                                  ) : (
                                    <span className="pat-time-slot__item-vital-data">{dayCol.pulseRate || "--"}</span>
                                  )}
                                </p>
                              </div>
                              <div className="vitals-grid-item">
                                <ReactTooltip className="card-tooltip" />
                                <p className="pat-time-slot__items">
                                  {dayCol.respiration.length > 12 ? (
                                    <span className="pat-time-slot__item-vital-data" data-tip={dayCol.respiration}>{dayCol.respiration.substr(0, 12) + "..."}</span>
                                  ) : (
                                    <span className="pat-time-slot__item-vital-data">{dayCol.respiration || "--"}</span>
                                  )}
                                </p>
                              </div>
                              <div className="vitals-grid-item">
                                <ReactTooltip className="card-tooltip" />
                                <p className="pat-time-slot__items">
                                  {dayCol.peakFlow.length > 12 ? (
                                    <span className="pat-time-slot__item-vital-data" data-tip={dayCol.peakFlow}>{dayCol.peakFlow.substr(0, 12) + "..."}</span>
                                  ) : (
                                    <span className="pat-time-slot__item-vital-data">{dayCol.peakFlow || "--"}</span>
                                  )}
                                </p>
                              </div>
                              <div className="vitals-grid-item">
                                <ReactTooltip className="card-tooltip" />
                                <p className="pat-time-slot__items">
                                  {dayCol.gripStrength.length > 12 ? (
                                    <span className="pat-time-slot__item-vital-data" data-tip={dayCol.gripStrength}>{dayCol.gripStrength.substr(0, 12) + "..."}</span>
                                  ) : (
                                    <span className="pat-time-slot__item-vital-data">{dayCol.gripStrength || "--"}</span>
                                  )}
                                </p>
                              </div>
                              <div className="vitals-grid-item">
                                <ReactTooltip className="card-tooltip" />
                                <p className="pat-time-slot__items">
                                  {dayCol.position.length > 12 ? (
                                    <span className="pat-time-slot__item-vital-data" data-tip={dayCol.position}>{dayCol.position.substr(0, 12) + "..."}</span>
                                  ) : (
                                    <span className="pat-time-slot__item-vital-data">{positionDisplay(dayCol.position) || "--"}</span>
                                  )}
                                </p>
                              </div>
                              <div className="vitals-grid-item">
                                <ReactTooltip className="card-tooltip" />
                                <p className="pat-time-slot__items">
                                  {dayCol.painScale.length > 12 ? (
                                    <span className="pat-time-slot__item-vital-data" data-tip={dayCol.painScale}>{dayCol.painScale.substr(0, 12) + "..."}</span>
                                  ) : (
                                    <span className="pat-time-slot__item-vital-data">{dayCol.painScale || "--"}</span>
                                  )}
                                </p>
                              </div>
                              <div className="vitals-grid-item">
                                <ReactTooltip className="card-tooltip" />
                                <p className="pat-time-slot__items">
                                  {dayCol.o2Saturation.length > 12 ? (
                                    <span className="pat-time-slot__item-vital-data" data-tip={dayCol.o2Saturation}>{dayCol.o2Saturation.substr(0, 12) + "..."}</span>
                                  ) : (
                                    <span className="pat-time-slot__item-vital-data">{dayCol.o2Saturation || "--"}</span>
                                  )}
                                </p>
                              </div>
                              <div className="vitals-grid-item">
                                <ReactTooltip className="card-tooltip" />
                                <p className="pat-time-slot__items">
                                  {dayCol.vision.length > 12 ? (
                                    <span className="pat-time-slot__item-vital-data" data-tip={dayCol.vision}>{dayCol.vision.substr(0, 12) + "..."}</span>
                                  ) : (
                                    <span className="pat-time-slot__item-vital-data">{dayCol.vision || "--"}</span>
                                  )}
                                </p>
                              </div>
                              <div className="vitals-grid-item">
                                <ReactTooltip className="card-tooltip" />
                                <p className="pat-time-slot__items">
                                  {dayCol.glucose.length > 12 ? (
                                    <span className="pat-time-slot__item-vital-data" data-tip={dayCol.glucose}>{dayCol.glucose.substr(0, 12) + "..."}</span>
                                  ) : (
                                    <span className="pat-time-slot__item-vital-data">{dayCol.glucose || "--"}</span>
                                  )}
                                </p>
                              </div>
                              <div className="vitals-grid-item">
                                <ReactTooltip className="card-tooltip" />
                                <p className="pat-time-slot__items">
                                  <span className="pat-time-slot__item-vital-data" data-tip={dayCol.comment}>{dayCol.comment.length > 12 ? dayCol.comment.substr(0, 12) + "..." : dayCol.comment || "--"}</span>
                                </p>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </Row>

                  {/* <Row className="schedule-row">
                <Col lg={2} md={2} sm={2} xs={4}>
                </Col>
                <Col lg={10} md={10} sm={10} xs={8}>
                  <div className="schedule-header">
                    <Row>
                      <Col>
                         <Carousel
                          ref={sliderEleRef}
                          itemsToShow={sliderCount}
                          pagination={false}
                          renderArrow ={({ type, onClick }) => {
                            const pointer = type === consts.PREV ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronRight} />;
                            return <button className="arrow-btn" onClick={onClick}>{pointer}</button>
                          }}
                          enableSwipe={false}
                          disableArrowsOnEnd={true}
                          enableMouseSwipe={false}
                          preventDefaultTouchmoveEvent={true}
                          onNextStart={onNextHandler}
                          onPrevStart={onPrevHandler}
                          className="schedule-header-carousel"
                          showArrows={props.data.length > sliderCount ? true : false}
                          >
                         {props.data.map(dayCol => {
                            return (
                            <div key={dayCol.recordDate} className="day-slot normal-text">
                              <span>{Dateformatter(dayCol.recordDate)}</span>
                              <span className="slot-date">{TableActions(dayCol, dayCol)}</span>
                            </div> 
                          )
                          })}
                        </Carousel> 
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            
             <Row noGutters className="profile-row flex-change"> 
              <Col lg={2} md={2} sm={2} xs={4} className="schedule-body-carousel">
              <ul className="pat-vital-slot" >
                  <li className="pat-time-slot__items">
                      <span className="pat-time-slot__item-vital">Weight (Kg)</span>
                  </li>
                  <li className="pat-time-slot__items">
                      <span className="pat-time-slot__item-vital">Height (cm)</span>
                  </li>
                  <li className="pat-time-slot__items">
                      <span className="pat-time-slot__item-vital">Temperature (c)</span>
                  </li>
                  <li className="pat-time-slot__items">
                      <span className="pat-time-slot__item-vital">BP-Systolic</span>
                  </li>
                  <li className="pat-time-slot__items">
                      <span className="pat-time-slot__item-vital">BP-Diastolic</span>
                  </li>
                  <li className="pat-time-slot__items">
                      <span className="pat-time-slot__item-vital">Heart Rate</span>
                  </li>
                   <li className="pat-time-slot__items">
                      <span className="pat-time-slot__item-vital">BMI</span>
                  </li>
                  <li className="pat-time-slot__items">
                      <span className="pat-time-slot__item-vital">BSA</span>
                  </li>
                  <li className="pat-time-slot__items">
                      <span className="pat-time-slot__item-vital">Pulse Rate</span>
                  </li>
                  <li className="pat-time-slot__items">
                      <span className="pat-time-slot__item-vital">Respiration</span>
                  </li>
                  <li className="pat-time-slot__items">
                      <span className="pat-time-slot__item-vital">Peak Flow</span>
                  </li>
                  <li className="pat-time-slot__items">
                      <span className="pat-time-slot__item-vital">Grip Strength</span>
                  </li>
                  <li className="pat-time-slot__items">
                      <span className="pat-time-slot__item-vital">Position</span>
                  </li>
                   <li className="pat-time-slot__items">
                      <span className="pat-time-slot__item-vital">Pain Scale</span>
                  </li>
                  <li className="pat-time-slot__items">
                      <span className="pat-time-slot__item-vital">o2 Saturation</span>
                  </li>
                  <li className="pat-time-slot__items">
                      <span className="pat-time-slot__item-vital">Vision</span>
                  </li>
                   <li className="pat-time-slot__items">
                      <span className="pat-time-slot__item-vital">Glucose</span>
                  </li>
                  <li className="pat-time-slot__items">
                      <span className="pat-time-slot__item-vital">Comments</span>
                  </li>
              </ul>

              </Col>
              <Col lg={10} md={10} sm={10} xs={8} className="slider-container schedule-body-carousel">
                <div className={`${props.data.length > sliderCount ? "slide-wrap-vital-arrows" : "slide-wrap-vital"}`}>
                <div className="pat-vital-row">
               <Carousel
                  ref={sliderEleRef}
                  itemsToShow={sliderCount}
                  pagination={false}
                  enableSwipe={false}
                  renderArrow ={({ type, onClick }) => {
                    const pointer = type === consts.PREV ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronRight} />;
                    const btnClassName = type === consts.PREV ? 'leftButton' : 'rightButton';
                    return <button className={btnClassName} style={{display: 'none'}} onClick={onClick}>{pointer}</button>
                  }}
                >
              
                {props.data.map(dayCol => {
                            return (
                            <>
                             <ul className="pat-time-slot" ref={sliderEleRef}>
                            <li className="pat-time-slot__items">
                            <span className="pat-time-slot__item-vital-data">{dayCol.Kg ? dayCol.Kg : "--"}</span>
                            </li>
                             <li className="pat-time-slot__items">
                            <span className="pat-time-slot__item-vital-data">{dayCol.Centimeter ? dayCol.Centimeter : "--"}</span>
                            </li>
                            <li className="pat-time-slot__items">
                            <span className="pat-time-slot__item-vital-data">{dayCol.temperature ? dayCol.temperature : "--"}</span>
                            </li>
                            <li className="pat-time-slot__items">
                            <span className="pat-time-slot__item-vital-data">{dayCol.bpSystolic ? dayCol.bpSystolic : "--"}</span>
                            </li>
                            <li className="pat-time-slot__items">
                            <span className="pat-time-slot__item-vital-data">{dayCol.bpDiastolic ? dayCol.bpDiastolic : "--"}</span>
                            </li>
                            <li className="pat-time-slot__items">
                            <span className="pat-time-slot__item-vital-data">{dayCol.heartRate ? dayCol.heartRate : "--"}</span>
                            </li>
                             <li className="pat-time-slot__items">
                            <span className="pat-time-slot__item-vital-data">{dayCol.BMI ? dayCol.BMI : "--"}</span>
                            </li>
                            <li className="pat-time-slot__items">
                            <span className="pat-time-slot__item-vital-data">{dayCol.BSA ? dayCol.BSA : "--"}</span>
                            </li>
                            <li className="pat-time-slot__items">
                            <span className="pat-time-slot__item-vital-data">{dayCol.pulseRate ? dayCol.pulseRate : "--"}</span>
                            </li>
                             <li className="pat-time-slot__items">
                            <span className="pat-time-slot__item-vital-data">{dayCol.respiration ? dayCol.respiration : "--"}</span>
                            </li>
                             <li className="pat-time-slot__items">
                            <span className="pat-time-slot__item-vital-data">{dayCol.peakFlow ? dayCol.peakFlow : "--"}</span>
                            </li>
                            <li className="pat-time-slot__items">
                            <span className="pat-time-slot__item-vital-data">{dayCol.gripStrength ? dayCol.gripStrength : "--"}</span>
                            </li>
                             <li className="pat-time-slot__items">
                            <span className="pat-time-slot__item-vital-data">{dayCol.position ? dayCol.position : "--"}</span>
                            </li>
                             <li className="pat-time-slot__items">
                            <span className="pat-time-slot__item-vital-data">{dayCol.painScale ? dayCol.painScale : "--"}</span>
                            </li>
                            <li className="pat-time-slot__items">
                            <span className="pat-time-slot__item-vital-data">{dayCol.o2Saturation ? dayCol.o2Saturation : "--"}</span>
                            </li>
                            <li className="pat-time-slot__items">
                            <span className="pat-time-slot__item-vital-data">{dayCol.vision ? dayCol.vision : "--"}</span>
                            </li>
                            <li className="pat-time-slot__items">
                            <span className="pat-time-slot__item-vital-data">{dayCol.glucose ? dayCol.glucose : "--"}</span>
                            </li>
                            <li className="pat-time-slot__items">
                            <ReactTooltip className="card-tooltip" />
                            <span className="pat-time-slot__item-vital-data" data-tip={dayCol.comment}>{dayCol.comment.length > 12 ? dayCol.comment.substr(0,12)+"..." : dayCol.comment || "--"}</span>
                            </li>
                            </ul>
                            </>
                          )
                  })}
                </Carousel>
                </div>
                </div>
              </Col>
             </Row> */}
                </>
              ) : null}
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default VitalsTable;
