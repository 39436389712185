/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext } from "react";
import { GlobalState } from '../../../src/context/ContextApi';

import {
  Card,
  CardBody,
  Col,
  Row,
  Collapse,
  Spinner
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleDown } from "@fortawesome/free-solid-svg-icons";

import Header from "../Layout/Header/Header";
import HeaderTitle from "../Layout/Header/HeaderTitle";
import PageLayout from "../Layout/PageLayout/PageLayout";
import { useTranslation } from "react-i18next";
import VitalsModal from "./Vitals/VitalsModal";
import VitalsTable from "./Vitals/VitalsTable";
import AllergyTable from "./Allergies/AllergyTable";
import AllergyModal from "./Allergies/AllergyModal";
import MedicationTable from "./Medications/MedicationTable";
import MedicationModal from "./Medications/MedicationModal";
import ImmunizationTable from "./Immunization/ImmunizationTable";
import ImmunizationModal from "./Immunization/ImmunizationModal";
import Axios from "axios";

import "./HealthProfile.css";
import ConditionsInput from "./Conditions/ConditionsInput";
import { cookieService } from '../../services/cookieService';

const HealthInfo = () => {
  const [isOpenVitalsInfo, setisOpenVitalsInfo] = useState(true);
  const [isOpenAllergyInfo, setisOpenAllergyInfo] = useState(true);
  const [isOpenMedication, setIsOpenMedication] = useState(true);
  const [isOpenImmunization, setIsOpenImmunization] = useState(true);
  const [isOpenCondition, setIsOpenCondition] = useState(true);
  const toggleVitals = () => setisOpenVitalsInfo(!isOpenVitalsInfo);
  const toggleAllergy = () => setisOpenAllergyInfo(!isOpenAllergyInfo);
  const toggleMedication = () => setIsOpenMedication(!isOpenMedication);
  const toggleImmunization = () => setIsOpenImmunization(!isOpenImmunization);
  const toggleCondition = () => setIsOpenCondition(!isOpenCondition);
  const { completeProfileChanged, setCompleteProfileChanged } = useContext(GlobalState);

  const [VitalsList, setVitalsList] = useState([]);
  const [vitalsLoaded, setVitalsLoaded] = useState(false);
  const [VitalsNoDataFound, setVitalsNoDataFound] = useState(false);
  const [VitalsLoading, setVitalsLoading] = useState(true);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  // let dependentID = cookieService.get('dependentID');
  let data = {}
  // if(dependentID){
  //   data = {'dependentID': dependentID}
  // }

  const FetchAllVitals = () => {

    
    setVitalsLoaded(false);
    window.showIncompleteProfileModal = true;
    Axios.get(API_ENDPOINT + "/patient/get-patient-all-vitals")
      .then((response) => {
        if (response.status === 200) {
          setVitalsList(response.data.data);
          if (response.data.data.length === 0) {
            setVitalsNoDataFound(true);
          } else {
            setVitalsNoDataFound(false);
          }
          setVitalsLoading(false);
          if(response.data.title !== "No data found"){
            setCompleteProfileChanged(!completeProfileChanged)
          }
        }
        setVitalsLoaded(true);
      })
      .catch((error) => {
        setVitalsNoDataFound(true);
        setVitalsLoading(false);
        setVitalsLoaded(true);
      });
  };

  const [AllergiesList, setAllergiesList] = useState([]);
  const [AllergiesNoDataFound, setAllergiesNoDataFound] = useState(false);
  const [AllergiesLoading, setAllergiesLoading] = useState(true);

  const fetchAllergies = () => {
    window.showIncompleteProfileModal = true;
    Axios.get(API_ENDPOINT + "/patient/get-patient-allergy-list")
      .then((res) => {
        setAllergiesList(res.data.data);
        if (res.data.data.length === 0) {
          setAllergiesNoDataFound(true);
        } else {
          setAllergiesNoDataFound(false);
        }
        setAllergiesLoading(false);
        if(res.data.title !== "No data found"){
          setCompleteProfileChanged(!completeProfileChanged)
        }
      })
      .catch((error) => {
        setAllergiesNoDataFound(true);
        setAllergiesLoading(false);
      });
  };

  const [MedicationsList, setMedicationsList] = useState([]);
  const [MedicationsNoDataFound, setMedicationsNoDataFound] = useState(false);
  const [MedicationsLoading, setMedicationsLoading] = useState(true);

  const fetchMedications = () => {
    window.showIncompleteProfileModal = true;
    Axios.post(API_ENDPOINT + "/patient/get-patient-all-medications-list", data)
      .then((res) => {
        setMedicationsList(res.data.data);
        if (res.data.data.length === 0) {
          setMedicationsNoDataFound(true);
        } else {
          setMedicationsNoDataFound(false);
        }
        setMedicationsLoading(false);
        if(res.data.title !== "No data found"){
          setCompleteProfileChanged(!completeProfileChanged)
        }
      })
      .catch((error) => {
        setMedicationsNoDataFound(true);
        setMedicationsLoading(false);
      });
  };
 
  

  const [ImmunizationList, setImmunizationList] = useState([]);
  const [ImmunizationNoDataFound, setImmunizationNoDataFound] = useState(false);
  const [ImmunizationsLoading, setImmunizationsLoading] = useState(true);

  const fetchImmunizations = () => {
    window.showIncompleteProfileModal = true;
    Axios.post(API_ENDPOINT + "/patient/get-patient-all-immunization-list", data)
      .then((res) => {
        setImmunizationList(res.data.data);
        console.log('148',res.data.data)
        if (res.data.data.length === 0) {
          setImmunizationNoDataFound(true);
        } else {
          setImmunizationNoDataFound(false);
        }
        setImmunizationsLoading(false);
        if(res.data.title !== "No data found"){
          setCompleteProfileChanged(!completeProfileChanged)
        }
      })
      .catch((error) => {
        setImmunizationNoDataFound(true);
        setImmunizationsLoading(false);
      });
  };

  let loader = null;
  if (MedicationsLoading || AllergiesLoading || VitalsLoading || ImmunizationsLoading) {
    loader = <Spinner />;
  }


  const { t } = useTranslation();

  return (
    <div className="HealthInfo h-auto">
      <Card>
        {loader}
        <CardBody className="card-body">
          <div className="health-info-collapse">
            <div
              color="primary"
              role="button"
              className="health-info-header-container"
            >
              <FontAwesomeIcon
                icon={isOpenCondition ? faAngleDown : faAngleRight}
                onClick={toggleCondition}
                className="align-middle"
              />
              <h2 className="health-info-header" onClick={toggleCondition}>
                {" "}
                {t("Conditions")}
              </h2>
            </div>
            <Collapse isOpen={isOpenCondition}>{<ConditionsInput />}</Collapse>
          </div>
          
          <div className="health-info-collapse">
            <div
              color="primary"
              role="button"
              className="health-info-header-container"
            >
              <FontAwesomeIcon
                icon={isOpenVitalsInfo ? faAngleDown : faAngleRight}
                onClick={toggleVitals}
                className="align-middle"
              />
              <h2 className="health-info-header" onClick={toggleVitals}>
                {" "}
                {t("Vitals")}
              </h2>
              <VitalsModal TableRenderVitals={FetchAllVitals} />
            </div>
            <Collapse isOpen={isOpenVitalsInfo}>
              {
                <VitalsTable
                  data={VitalsList}
                  vitalsLoaded={vitalsLoaded}
                  VitalsNoDataFound={VitalsNoDataFound}
                  TableRenderVitals={FetchAllVitals}
                />
              }
            </Collapse>
          </div>
          <div className="health-info-collapse">
            <div
              color="primary"
              role="button"
              className="health-info-header-container"
            >
              <FontAwesomeIcon
                onClick={toggleAllergy}
                icon={isOpenAllergyInfo ? faAngleDown : faAngleRight}
                className="align-middle"
              />
              <h2 className="health-info-header" onClick={toggleAllergy}>
                {t("Allergy")}
              </h2>
              <AllergyModal TableRenderAllergies={fetchAllergies} />
            </div>
            <Collapse isOpen={isOpenAllergyInfo}>
              {
                <AllergyTable
                  data={AllergiesList}
                  AllergiesNoDataFound={AllergiesNoDataFound}
                  TableRenderAllergies={fetchAllergies}
                />
              }
            </Collapse>
          </div>
          <div className="health-info-collapse">
            <div
              color="primary"
              role="button"
              className="health-info-header-container"
            >
              <FontAwesomeIcon
                onClick={toggleMedication}
                icon={isOpenMedication ? faAngleDown : faAngleRight}
                className="align-middle"
              />
              <h2 className="health-info-header" onClick={toggleMedication}>
                {t("Medication")}
              </h2>
              <MedicationModal TableRenderMedications={fetchMedications} />
            </div>
            <Collapse isOpen={isOpenMedication}>
              {
                <MedicationTable
                  data={MedicationsList}
                  MedicationsNoDataFound={MedicationsNoDataFound}
                  TableRenderMedications={fetchMedications}
                />
              }
            </Collapse>
          </div>
          <div className="health-info-collapse">
            <div
              color="primary"
              role="button"
              className="health-info-header-container"
            >
              <FontAwesomeIcon
                onClick={toggleImmunization}
                icon={isOpenImmunization ? faAngleDown : faAngleRight}
                className="align-middle"
              />
              <h2 className="health-info-header" onClick={toggleImmunization}>
                {t("Immunization")}
              </h2>
              <ImmunizationModal 
              TableRenderImmunizations={fetchImmunizations} 
              />
            </div>
            <Collapse isOpen={isOpenImmunization}>
              {
                <ImmunizationTable
                  data={ImmunizationList}
                  MedicationsNoDataFound={ImmunizationNoDataFound}
                  TableRenderImmunizations={fetchImmunizations}
                />
              }
            </Collapse>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const HealthProfile = () => {

  return (
    <PageLayout>
      <Header>
        <HeaderTitle>Health Profile</HeaderTitle>
      </Header>

      <Row>
        <Col md="12">
          <HealthInfo />
        </Col>
      </Row>
    </PageLayout>
  );
};
export default HealthProfile;
