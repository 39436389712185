import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
import MomentConfig from '../../../common/MomentConfig';
import { useHistory, useLocation } from "react-router-dom";
import { GlobalState } from "../../../context/ContextApi"
import { Card, CardBody, Col, Button } from "reactstrap";
import { faVideo, faPaperclip, faFileInvoiceDollar, faBan } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import styles from "../Consultation.module.css";
import avatar from "../../../assets/avatar.png";
import DetailsModal from "./DetailsModal";
import ReactTooltip from 'react-tooltip';
import { toastr } from "react-redux-toastr";
import CryptoJS from "crypto-js";
import { cookieService } from '../../../services/cookieService';
import axios from "axios";
import ManageFilesModal from './ManageFilesModal';
import CancelAppointmentModal from './CancelAppointmentModal';


const ConsultationCard = (props) => {
  // DEPENDENCY VARIABLES
  const { id, schStartTime, schEndTime, physicianId, status, isRedirected} = props

  // GLOBAL CONTEXT
  const {
    setPhysicianName,
    setAppointmentEndTime,
    setAppointmentTime,
    setAppointmentId,
    setVideoConsultInfo,
    setTimerDuration,
    setPaymentNote,
    setPhysicianTitle,
  } = useContext(GlobalState)
  
  // LOCAL VARIABLES
  const { t } = useTranslation();
  let history = useHistory();
  let location = useLocation();
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [buttonStatus, setButtonStatus] = useState(false)
  const [cancelApptButtonStatus, setCancelApptButtonStatus] = useState(true)
  const [start, setStart] = useState(false)
  const [manageFilesModal, setManageFilesModal] = useState(false)
  const [stop, setStop] = useState(false) //eslint-disable-line
  const appointmentTime = moment.utc(props.consultation_time).local().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME);
  const appointmentEndTime = moment.utc(props.schEndTime).local().format("h:mm A");
  const physicianName = props.physician_name;
  const appointmentId = props.id;
  const paymentMode = props.paymentNote;
  const [cancelAppointmentModal, setCancelAppointmentModal] = useState(false);
  const [cancelRuleStatus, setCancelRuleStatus] = useState("");

  const handleClick = () => {
    setAppointmentId(id);
    setVideoConsultInfo(props);
    setAppointmentTime(appointmentTime);
    setAppointmentEndTime(appointmentEndTime);
    setPhysicianName(physicianName);
    setPhysicianTitle(props.physician_title);

    var ciphertext = CryptoJS.AES.encrypt(id, 'lock');
    if (navigator.userAgent.indexOf("soiOSApp") > -1) {
      if (cookieService.get('token')) {
        window.open(process.env.REACT_APP_URL + "/iosapp/" + encodeURIComponent(ciphertext) + "/" + encodeURIComponent(cookieService.get('token')));
      }
      history.push({
          pathname: '/consultation-interstitial',
          state: { detail: {id: encodeURIComponent(ciphertext)} }
      })
    } else {
      checkForMediaPermissions((data) => {
        if (data) {
          history.push(`video-consultation/${ciphertext}?aid=${btoa(appointmentId)}`)
        } else {
          toastr.error("Allow Equipo to access this device's Camera & Microphone.");
        }
      });
    }
  }

  const checkForMediaPermissions = (callback) => {
    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(function (stream) {
      callback(true);
    })
      .catch(function (err) {
        callback(false);
      });
  }

  const enableButtonOnTime = () => {
    const date = new Date()
    const currentTime = moment.utc(date).local()
    const startTime = moment.utc(schStartTime).local().subtract(15, 'minutes')
    let duration = moment.duration(startTime.diff(currentTime))._milliseconds
    let timeOut = duration < 0 ? 0 : duration

    // TIMEOUT FOR ENABLING BUTTON 
    setTimeout(() => {
      setStart(true);
      setButtonStatus(true);
    }, timeOut);
  }

  const disableButtonOnTime = () => {
    const date = new Date()
    const currentTime = moment.utc(date).local()
    const endTime = moment.utc(schEndTime).local().add(60, 'minutes')
    let duration = moment.duration(endTime.diff(currentTime))._milliseconds
    let timeOut = duration < 0 ? 0 : duration

    // TIMEOUT FOR DISABLING BUTTON 
    setTimeout(() => {
      setStop(true);
      setButtonStatus(false);
    }, timeOut);
  }

  const createTimerDuration = () => {
    const date = new Date()
    const currentTime = moment.utc(date).local()
    const endTime = moment.utc(schEndTime).local()
    let duration = moment.duration(endTime.diff(currentTime))._milliseconds
    let timeDuration = duration < 0 ? 0 : duration

    // SAVE DURATION TO GLOBAL CONTEXT
    setTimerDuration(timeDuration)
  }

  const disableCancelApptButtonOnTime = () => {
    const date = new Date()
    const currentTime = moment.utc(date).local()
    const endTime = moment.utc(schStartTime).local()
    let duration = moment.duration(endTime.diff(currentTime))._milliseconds
    let timeOut = duration < 0 ? 0 : duration

    // TIMEOUT FOR DISABLING BUTTON 
    setTimeout(() => {
      setCancelApptButtonStatus(false);
    }, timeOut);
  }

  const downloadAppointmentPdf=()=>{
    axios.post(`${API_ENDPOINT}/pdf/generate-patient-appointment-invoice-pdf`,{
      appointmentId: appointmentId,
    }, { responseType: 'blob' })
      .then(function (response) {
        var fileDownload = require('js-file-download');
        fileDownload(response.data, response["headers"]["x-suggested-filename"] || 'report.pdf');
      })
  }

  useEffect(() => { if (!buttonStatus && !start) enableButtonOnTime() }, [buttonStatus]) //eslint-disable-line
  useEffect(() => { if (buttonStatus && start) { disableButtonOnTime(); createTimerDuration() } }, [buttonStatus]) //eslint-disable-line
  useEffect(() => { disableCancelApptButtonOnTime(); }, []) //eslint-disable-line

  return (
    <Col md={6}>

      <ManageFilesModal
        manageFilesModal={manageFilesModal}
        setManageFilesModal={setManageFilesModal}
        schEndTime={schEndTime}
      />

      { props.status === "Scheduled"
        ? <CancelAppointmentModal
          cancelAppointmentModal={cancelAppointmentModal}
          setCancelAppointmentModal={setCancelAppointmentModal}
          parent="consultationCard"
          setCancelRuleStatus={setCancelRuleStatus}
          appointmentId={id}
        />
        : ""}

        <Card className={classNames("mb-3 bg-light ", styles.profileCard)}>
          <CardBody className="p-3 consultation-card-body">
            <ReactTooltip className="card-tooltip" />
            <div className={styles.profileWrapper}>
              <div className={classNames(styles.imageWrapper, 'profile-wrapper')}>
                <img
                  src={props.profile_image ? props.profile_image : avatar}
                  width="90"
                  height="90"
                  className="rounded-circle consultation-image object-cover"
                  alt="Avatar"
                />
              </div>
              <div
                className={classNames(
                  styles.profileContent,
                  styles.cCProfileContent,
                  'profile-content-section'
                )}
              >
                <div className={styles.profileData}>
                  <h6 className="pt-2">
                    {moment.utc(props.consultation_time).local().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}

                  </h6>
                  {(props.patient_name) ?
                  <div className={styles.scheduleContent}>
                    <div className={styles.scheduleContentLabel}>
                      Dependent
                    </div>
                    <div className={styles.scheduleContentValue}>
                    {props.patient_name}
                    </div>
                  </div>:""}
                  <div className={styles.scheduleContent}>
                    <div className={styles.scheduleContentLabel}>
                      Care Provider
                    </div>
                    <div className={styles.scheduleContentValue}>
                    {props.physician_title?props.physician_title+" ":null}{props.physician_name}
                    </div>
                  </div>
                  {(props.specialityId && props.specialityText) ?
                  <div className={styles.scheduleContent}>
                    <div className={styles.scheduleContentLabel}>
                      Speciality
                    </div>
                    <div className={styles.scheduleContentValue}>
                    {props.specialityText? props.specialityText:'N/A'}
                    </div>
                  </div>:""}
                  <div className={styles.scheduleContent}>
                    <div className={styles.scheduleContentLabel}>Chief Complaint</div>
                    <div className={styles.scheduleContentValue + " chiefcomplaint"}>
                      {props.chief_complaint ? (<p data-tip={props.chief_complaint}>{props.chief_complaint}</p>) : "---"}
                    </div>
                  </div>

                  <div className={styles.scheduleContent}>
                    <div className={styles.scheduleContentLabel}>
                      Status
                    </div>
                    <div className={styles.scheduleContentValue}>
                      {props.status}
                    </div>
                  </div>
                
                </div>
                <div
                  className={classNames(styles.btnWrapper, styles.cCBtnWrapper, 'book-btn-wrapper')}
                >
                  {props.status === "Completed" || props.status === "Cancelled" 
                  ? <DetailsModal id={props.id} consultationDetails={props.consultationDetails} isRedirected={isRedirected}/> 
                  : props.status !== "Cancelled" && <Button
                    className="consultation-card-buttons"
                    color={buttonStatus ? "primary" : "secondary"}
                    style={buttonStatus ? { cursor: "pointer" } : { cursor: "not-allowed" }}
                    size="md" onClick={handleClick}
                    title={buttonStatus ? "Start Video Consultation" : "You can join an appointment 15 minutes before the scheduled time"}
                    disabled={buttonStatus ? false : true}
                    >
                    <FontAwesomeIcon icon={faVideo} fixedWidth className="align-middle mr-2"
                    />
                    {t("Join Appointment")}

                  </Button>}
                {props.status === "Completed" || props.status === "Cancelled"
                  ? <Button 
                    style={{padding: '4px 15px'}}
                    color="primary"
                    className="margin-left"
                    title="Download Receipt"
                    onClick={() => downloadAppointmentPdf()}>
                    <FontAwesomeIcon
                      icon={faFileInvoiceDollar}
                      color="white"
                    /> {" "}
                  </Button>
                  : ""}
                {props.status === "Cancelled"
                  ? ""
                  : <Button
                    style={{padding: '4px 14px'}}
                    color="primary"
                    className="margin-left"
                    title="Manage Files"
                    onClick={() => {
                      let path = location.pathname
                      history.push(`${path}?aid=${btoa(appointmentId)}&pid=${btoa(physicianId)}&status=${btoa(status)}`)
                      setManageFilesModal(true)
                    }}>
                    <FontAwesomeIcon
                      icon={faPaperclip}
                      color="white"
                    /> {" "}
                  </Button>}

                {props.status === "Scheduled" && cancelApptButtonStatus && (props.paymentNote === "coupon" || cancelRuleStatus === "loaded")
                  ? <Button
                    color="primary"
                    className="margin-left consultation-card-buttons"
                    title="Cancel Appointment"
                    onClick={() => {
                      setCancelAppointmentModal(true);
                      setPhysicianName(physicianName);
                      setAppointmentId(id);
                      setPaymentNote(paymentMode);
                      setPhysicianTitle(props.physician_title);
                      }}>  
                    <FontAwesomeIcon
                      icon={faBan}
                      color="white"
                    /> Cancel Appointment
                    {" "}
                  </Button>
                  : ""}

                </div>
              </div>
            </div>
          </CardBody>
        </Card>
    </Col>
    
  );
};

export default ConsultationCard;
