import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { cookieService } from '../../services/cookieService';
import axios from '../../axios.js';
import axiosInstance from "../../axios.js";
import { toastr } from "react-redux-toastr";
import DatePicker from "react-datepicker";
import {
  Button,
  Card,
  CardBody,
  CustomInput,
  Label,
  Row,
  Col,
  FormGroup,
  Spinner
} from "reactstrap";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { useTranslation } from 'react-i18next';
import './SignUp.css';
import { useInput } from '../../hooks/input-hook';
import logo from "../../assets/equipo-logo_low-res_with-tagline.png";
import Select from "react-select";
import moment from "moment";
import { useDropInput } from "../../hooks/drop-down-hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from 'react-tooltip';
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { PasswordRegex } from "../../common/helpers.js";


const SignUp = (props) => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const { value: firstName, bind: bindFirstName } = useInput('');
  const { value: middleName, bind: bindMiddleName } = useInput('');
  const { value: lastName, bind: bindLastName } = useInput('');
  const { value: email, bind: bindEmail } = useInput('');
  const { value: password, bind: bindPassword } = useInput('');
  const { value: confirmPassword, bind: bindConfirmPassword } = useInput(''); // eslint-disable-line
  const [CountryName, setCountryName] = useState("");
  const [countryID, setCountryID] = useState("");
  const [agreementCheck, setAgreementCheck] = useState(false);
  const [gender, setGender] = useState("");
  const { value: dob, bind: bindDOB, setValue: setDOB } = useInput(""); // eslint-disable-line
  const { value: phoneNumber, bind: bindPhone, setValue: setPhone } = useInput(""); // eslint-disable-line
  const [countryMap, setCountry] = useState([]);
  const [states, setStates] = useState([])
  const [userState, setUserState] = useState("")
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState([]);
  const [errorStatus, setErrorStatus] = useState(false); // eslint-disable-line
  const [spinnerEnable, setSpinnerEnable] = useState(false);

  const { value: StartDate, bind: bindStartDate, setValue: setStartDate } = useInput(""); // eslint-disable-line
  const { value: ExpiryDate, bind: bindExpiryDate, setValue: setExpiryDate } = useInput(""); // eslint-disable-line

  const { value: holderName, bind: bindHolderName } = useInput('');
  const { value: policyNumber, bind: bindPolicyNumber } = useInput('');
  // const [selectedInsurance, setSelectedInsurance] = useState('');

  const [searchedInsurance, setSearchedInsurance] = useState("");
  // const [showAddInsurance, setShowAddInsurance] = useState(false)
  const [dropDownState, setDropDownState] = useState(false)
  const [apiLoaded, setApiLoaded] = useState(false)
  const [InsuranceValue, setInsuranceValue] = useState("");
  const [Insurances, setInsurances] = useState([]);
  const [Insurance, setInsurance] = useState("");
  const [isCountryBlur, setIsCountryBlur] = useState(false);
  const [isDobBlur, setIsDobBlur] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);

  function setCountryMap(country) {
    setCountryList({
      value: country.country_name,
      label: country.country_name,
    });
    setCurrency({
      value: country.country_currency,
      label: country.country_currency,
    });
    setCountryCode({
      value: country.country_isd_code,
      label: country.country_isd_code,
    });
    setCountryName(country.country_name);
    setCountryID(country.id)
  }

  const onChangeList = (event, errors, values) => {
    setCountryList(event);
    if (countryMap.length > 0) {
      countryMap
        .filter((key) => key.country_name == event.value) // eslint-disable-line
        .map((filterCountry) => setCountryMap(filterCountry));
    }
  };

  let countryList = countryMap.map(function (list) {
    return { value: list.country_name, label: list.country_name };
  });
  let countryCodeList = countryMap.map(function (list) {
    return { value: list.country_isd_code, label: list.country_isd_code };
  });
  let countryCurrenyList = countryMap.map(function (list) { // eslint-disable-line
    return { value: list.country_currency, label: list.country_currency };
  });
  const {
    value: countryCode,
    bind: bindCountryCode, // eslint-disable-line
    setValue: setCountryCode,
  } = useDropInput({ value: "", label: "" });
  const {
    value: currency,
    bind: bindCurrency, // eslint-disable-line
    setValue: setCurrency,
  } = useDropInput({ value: "", label: "" });
  const [country, setCountryList] = useState({
    value: "",
    label: "Select Country",
  });

  let history = useHistory();
  useEffect(() => {
    let token = cookieService.get('token');
    if (token && token.length > 0) {
      history.push('/settings/profile')
    }
  });
  const handleAgreement = () => {
    setAgreementCheck(!agreementCheck);
  }
  const handleSubmit = (event, values, errors) => {
    // DISPLAY SPINNER
    // if (!gender) { toastr.error("Gender is not selected", "Please try again") }
    // if (!CountryName) { toastr.error("The country is not selected ", "Please try again") }
    // if (!dob) { toastr.error("Date Of Birth Is Empty ", "Please try again") }
    if (agreementCheck) {
      setSpinnerEnable(!spinnerEnable);
      const request = {
        fname: firstName,
        mname: middleName,
        lname: lastName,
        email: email,
        password: password,
        dob: moment(dob).format('YYYY-MM-DD'),
        country: CountryName,
        countryID: countryID,
        countryCode: countryCode.value,
        currency: currency.value,
        state: userState,
        gender: values?.gender,
        mobile: phoneNumber,
        insuranceName: Insurance.value,
        holderName: holderName,
        policyNumber: policyNumber,
        startDate: StartDate ? moment(StartDate).format('YYYY-MM-DD') : '',
        expiryDate: ExpiryDate ? moment(ExpiryDate).format('YYYY-MM-DD') : ''
      }

      axios.post('/patient/register', request)
        .then(response => {
          if (response.status === 201) {
            toastr.success("Thanks for creating your account", "Before starting, please verify your account by clicking on the link that was sent to the email you specified");
            history.push('/');
          }
        })
        .catch(function (error) {
          const errorMsg = (error.response && error.response.data && error.response.data.data && error.response.data.data.validation) || (error && error.response && error.response.statusText);
          setErrorStatus(true);
          setErrorMessage([...errorMessage, errorMsg]);
          toastr.error(errorMsg);
          setSpinnerEnable(false);
        });

    }
  }

  function setCountryDetail(countryCode) {
    if (countryCode == "" || countryCode == undefined) { // eslint-disable-line
      countryCode = "";
    }
    setCountryCode({ value: countryCode, label: countryCode });
    if (countryMap.length > 0) {
      countryMap
        .filter((key) => key.country_isd_code == countryCode) // eslint-disable-line
        .map((filterCountry) => setCountryMap(filterCountry));
    } else {
      axiosInstance
        .get("/common/country-list")
        .then(function (response) {
          if (response.status === 200) {
            setCountry(response.data.data);
          }
        })
        .catch(function (error) { });
    }
  }

  function setCountryMap(country) { // eslint-disable-line
    setCountryList({
      id: country.id,
      value: country.country_name,
      label: country.country_name,
    });
    setCurrency({
      value: country.country_currency,
      label: country.country_currency,
    });
    setCountryCode({
      value: country.country_isd_code,
      label: country.country_isd_code,
    });
    setCountryName(country.country_name);
    setCountryID(country.id);
  }

  useEffect(() => {
    setStates([])
    setUserState("")
    if (country?.id) {
      axios.get(`common/state-list/${country?.id}`)
        .then(
          response => {
            const StatesList = response.data.data.map((item) => {
              if (country?.id === '223')
                return { value: item.shortCode, label: item.stateName }
              else if (country?.id === '103')
                return { value: item.countyCode, label: item.countyName }
            });
            setStates(StatesList)
          })
        .catch(error => {
          console.log("Error Loading States List", error);
        });
    }
  }, [country])
  const passwordFormat = () => {
    return (
      <div className="password_tooltip">
        <span data-tip={t("passwordValidateMsg")}
        >
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="align-middle"
          />
        </span>
        <ReactTooltip className="doc-upload-tooltip" />
      </div>
    )
  }

  useEffect(() => { setCountryDetail() }, []) // eslint-disable-line

  let InsuranceList = [];
  if (InsuranceValue.length >= 4) {
    InsuranceList = Insurances.map((key) => {
      // if (key.id) {
      //   return { value: key.id, label: key.insuranceName };
      // } else {
      return { value: key.id, label: key.name };
      // }
    });
  }

  const InsuranceHandler = (value) => {
    setTimeout(() => {
      value ? setInsurance(value) : setInsurance([])

      if (Insurances.length > 0) {
        Insurances.filter((key) => key.id == value).map((filterIns) => { // eslint-disable-line
          setInsurance({
            value: filterIns.id,
            label: `${filterIns.name}`,
          });
        });
      }
    }, 500);
  };

  useEffect(() => { searchedInsurance && apiLoaded ? setDropDownState(true) : setDropDownState(false) }) // eslint-disable-line

  useEffect(() => {
    if (InsuranceValue.length >= 4) FetchInsurances();
    //  InsuranceValue.length >= 3 || searchedInsurance.length >= 3 ? setShowPlusButton(true) : setShowPlusButton(false)
  }, [InsuranceValue, searchedInsurance]) // eslint-disable-line

  const FetchInsurances = (source) => {
    // setSpinner(true)
    const params = {
      insuranceName: InsuranceValue ? InsuranceValue : searchedInsurance,
    }

    axios.post(
      API_ENDPOINT + "/common/insurance-search", params,
      // {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // }
    )
      .then((response) => {
        // setSpinner(false)
        if (response.status === 200 && response.data.data.length > 0) {
          setInsurances(response.data.data)
          setApiLoaded(true)
          // if (source === "addMedication") setNewMedicineAdded(true)
        }
        // if (response.status === 200 && response.data.data.length === 0) {
        //   setShowAddInsurance(true)
        //   if (source === "normalSearch") setShowPlusButton(false)
        // } else {
        //   setShowAddInsurance(false)
        // }
      })
      .catch((error) => {
        // setSpinner(false);
        setApiLoaded(false);
      });
  };

  const handleFieldErrors = (event, errors, value) => {
    if (!CountryName) { setIsCountryBlur(true) }
    if (!dob) { setIsDobBlur(true) }
  }

  return (
    <>
      <div className="main">
        <div className="container">
          <div className="login-wrapper sign-up">
            <Card>
              <CardBody>
                <div className="m-sm-4">
                  <div className="text-center image-wrapper">
                    <img
                      src={logo}
                      alt={t('altTextAppLogo')}
                      className="img-fluid"
                      width="255"
                    />
                  </div>
                  <AvForm onValidSubmit={handleSubmit} onInvalidSubmit={handleFieldErrors}>
                    <Row >
                      <Col md="4">
                        <Label>{t('firstName')}{" "}<span className="mandatory-marker">*</span></Label>
                        <AvField
                          bsSize="lg"
                          name="fname"
                          type="text"
                          validate={{
                            maxLength: { value: 40 }
                          }}
                          placeholder={t('enterFirstNamePH')}
                          {...bindFirstName}
                          errorMessage={t('requiredField')}
                          required
                        />
                      </Col>
                      <Col md="4">
                        <Label>Middle Name</Label>
                        <AvField
                          bsSize="lg"
                          name="mname"
                          type="text"
                          validate={{
                            maxLength: { value: 40 }
                          }}
                          placeholder={t('enterMiddleNamePH')}
                          {...bindMiddleName}
                        />
                      </Col>
                      <Col md="4">
                        <Label>Last Name{" "}<span className="mandatory-marker">*</span> </Label>
                        <AvField
                          bsSize="lg"
                          name="lname"
                          type="text"
                          validate={{
                            maxLength: { value: 40 }
                          }}
                          placeholder="Enter your last name"
                          {...bindLastName}
                          errorMessage={t('requiredField')}
                          required
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md="8">
                        <FormGroup>
                          <Label for="exampleCheckbox">{t("gender")}{" "}<span className="mandatory-marker">*</span></Label>
                          <div>
                            {/* <CustomInput
                              type="radio"
                              id="Male"
                              name="customRadio"
                              label={t("male")}
                              className="mb-2 gender-flex"
                              value="Male"
                              checked={gender === "Male"}
                              onChange={() => setGender("Male")}
                              inline
                            />
                            <CustomInput
                              type="radio"
                              id="Female"
                              name="customRadio"
                              label={t("female")}
                              value="Female"
                              checked={gender === "Female"}
                              onChange={() => {
                                setGender("Female");
                              }}
                              className="mb-2 gender-flex"
                              inline
                            />
                            <CustomInput
                              type="radio"
                              id="Prefer not to answer"
                              name="customRadio"
                              label={t("pfrNotAns")}
                              value="Male"
                              checked={gender === "Other"}
                              onChange={() => setGender("Other")}
                              className="mb-2 gender-flex"
                              inline
                            /> */}

                            <AvRadioGroup inline name="gender" className="radioBtn" required errorMessage={t('requiredField')}>
                              <AvRadio customInput label="Male" value="Male" />
                              <AvRadio customInput label="Female" value="Female" />
                              <AvRadio customInput label="Prefer not to answer" value="Other" />
                            </AvRadioGroup>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <Label for="exampleDate2">{t("dobfield")}{" "}<span className="mandatory-marker">*</span></Label>
                        {/* <AvField
                          name="dob"
                          type="date"
                          placeholder="date placeholder"
                          id="dob"
                          {...bindDOB}
                          max={moment().format("YYYY-MM-DD")}
                          errorMessage="T  let InsuranceList = [];
  if (InsuranceValue.length >= 3) {
    InsuranceList = Insurances.map((key) => {
      // if (key.id) {
      //   return { value: key.id, label: key.insuranceName };
      // } else {
        return { value: key.id, label: key.name };
      // }
    });
  }

  const InsuranceHandler = (value) => {
    setTimeout(() => {
    value ? setInsurance(value) : setInsurance([])

    if (Insurances.length > 0) {
      Insurances.filter((key) => key.id == value).map((filterIns) => { // eslint-disable-line
        setInsurance({
          value: filterIns.id,
          label: `${filterIns.name}`,
        });
      });
    }
    }, 500);
  };his field is required"
                          required
                        /> */}
                        <div className="form-group">
                          <div className="sign-up-dob-input">
                            <DatePicker
                              dateFormat="dd MMM YYY"
                              onChange={(date) => { setDOB(date); }}
                              placeholderText="DD MM YYYY"
                              selected={dob}
                              showYearDropdown
                              scrollableYearDropdown
                              showMonthDropdown
                              maxDate={moment().subtract('18', 'years').toDate()}
                              onBlur={() => setIsDobBlur(true)}
                              className={(!dob && isDobBlur) ? "red-border" : ""}
                            />

                            {!dob && isDobBlur && <div className="d-block invalid-feedback">This field is required</div>}

                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="5" md="5">
                        <FormGroup>
                          <Label>{t("country")}{" "} <span className="mandatory-marker">*</span></Label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={countryList}
                            onChange={onChangeList}
                            value={country}
                            required
                            onBlur={() => setIsCountryBlur(true)}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: (!CountryName && isCountryBlur) ? "#F26666" : baseStyles.borderColor
                              })
                            }}
                          />
                          {!CountryName && isCountryBlur && <div className="d-block invalid-feedback">This field is required</div>}
                        </FormGroup>
                      </Col>
                      <Col lg="3" md="3">
                        <FormGroup>
                          <Label>{t("countryCode")}{" "}</Label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            value={countryCode}
                            isDisabled
                            options={countryCodeList}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4" md="4">
                        <Label>{t("State")}{" "}</Label>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={states}
                          placeholder="Select State"
                          onChange={(event) => {
                            setUserState(event.value)
                          }}
                          value={states.filter(state => state.value === userState)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6" md="6">
                        <Label>{t('email')}{" "} <span className="mandatory-marker">*</span></Label>
                        <AvField
                          bsSize="lg"
                          name="email"
                          placeholder={t('enterEmailPH')}
                          {...bindEmail}
                          type="email"
                          validate={{ email: true, maxLength: { value: 254 } }}
                          errorMessage={t('emailErrorMessage')}
                          required
                        />
                      </Col>
                      <Col lg="6" md="6">
                        <Label>{t("phoneNumber")}{" "} <span className="mandatory-marker">*</span></Label>
                        <AvField
                          bsSize="lg"
                          name="phone"
                          type="text"
                          placeholder={t("phoneNumber")}
                          id="phone"
                          validate={{
                            pattern: { value: '^[0-9]+$', errorMessage: 'only numbers allowed' },
                            maxLength: { value: 12 }
                          }}
                          {...bindPhone}
                          errorMessage="This field is required"
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>{t('password')} {passwordFormat()} <span className="mandatory-marker move-right">*</span></Label>
                        <div className="position-relative">
                          <AvField
                            bsSize="lg"
                            name="password"
                            type={visiblePassword ? "text" : "password"}
                            placeholder={t('enterPasswordSignUp')}
                            {...bindPassword}
                            minLength="8"
                            validate={{
                              maxLength: { value: 24 },
                              pattern: { value: PasswordRegex, errorMessage: t('passwordValidateMsg') }
                            }}
                            required
                            autoComplete="new-password"
                            className="password-show"
                          />
                          <span
                            className="position-absolute cursor-pointer"
                            style={{ right: "10px", top: "8px" }}
                            onClick={() => {
                              setVisiblePassword(!visiblePassword);
                            }} >
                            {visiblePassword ? (
                              <FaEyeSlash />
                            ) : (
                              <FaEye />
                            )}
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <Label>{t('confirmPassword')}{" "} <span className="mandatory-marker">*</span></Label>
                        <AvField
                          bsSize="lg"
                          name="confirm-password"
                          type="password"
                          placeholder={t('confirmPassword')}
                          {...bindConfirmPassword}
                          errorMessage={t('confirmPasswordErrorMessage')}
                          validate={{
                            match: { value: 'password' },
                            maxLength: { value: 24 }
                          }}
                          required />
                      </Col>
                    </Row>
                    <Row >&nbsp;</Row>
                    <Row >
                      <Col md="12"><h5 className="mb-10 card-title">Insurance Details</h5></Col>
                      <Col md="4" >
                        <Label className="d-flex">Insurance Name
                          <div className="ml-2">
                            <span data-tip="Enter 4 letters to start search..." >
                              <FontAwesomeIcon
                                icon={faExclamationCircle}
                                className="align-middle"
                              />
                            </span>
                            <ReactTooltip className="doc-upload-tooltip" />
                          </div>
                        </Label>
                        <div className="form-group">
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={InsuranceList}
                            value={Insurance}
                            onInputChange={(event) => {
                              if (event && event.length >= 4) setSearchedInsurance(event)
                              setInsuranceValue(event)
                              // setNewDrugName()
                            }}
                            onChange={(event) => {
                              setSearchedInsurance("")
                              InsuranceHandler(event);
                            }}

                            menuIsOpen={dropDownState}
                            placeholder="Type here for insurance"
                            // isLoading={spinner}
                            isSearchable
                            isClearable
                          />
                        </div>
                      </Col>

                      <Col md="4">
                        <Label>Holder Name</Label>
                        <AvField
                          bsSize="lg"
                          name="holderName"
                          type="text"
                          validate={{
                            maxLength: { value: 40 }
                          }}
                          placeholder="Enter Insurance Holder Name"
                          {...bindHolderName}
                        />
                      </Col>
                      <Col md="4">
                        <Label>Policy Number{" "} </Label>
                        <AvField
                          bsSize="lg"
                          name="policyNumber"
                          type="text"
                          validate={{
                            maxLength: { value: 40 }
                          }}
                          placeholder="Enter Policy Number"
                          {...bindPolicyNumber}
                          errorMessage={t('requiredField')}
                        // required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <Label for="startDate">Start Date</Label>
                        <div className="form-group">
                          <div className="sign-up-dob-input">
                            <DatePicker
                              dateFormat="dd MMM yyyy"
                              selected={StartDate}
                              maxDate={moment().toDate()}
                              onChange={(date) => { setStartDate(date); }}
                              placeholderText="DD MM YYYY"
                              showYearDropdown
                              showMonthDropdown
                            />


                          </div>
                        </div>
                      </Col><br></br>
                      <Col md="4">
                        <Label for="expiryDate">Expiry Date</Label>
                        <div className="form-group">
                          <div className="sign-up-dob-input">
                            <DatePicker
                              dateFormat="dd MMM yyyy"
                              selected={ExpiryDate}
                              minDate={moment().toDate()}
                              onChange={(date) => { setExpiryDate(date); }}
                              placeholderText="DD MM YYYY"
                              showYearDropdown
                              showMonthDropdown
                            />

                          </div>
                        </div></Col>
                    </Row>
                    <AvInput
                      tag={CustomInput}
                      type="checkbox"
                      name="customCheckbox"
                      className="i-agree"
                      label={t('User Acceptance')}
                      onChange={handleAgreement}
                      required
                    />
                    {spinnerEnable &&
                      (<div className="spinner-wrapper">
                        <Spinner color="dark" type="grow" className="mr-2" size="sm" />
                        <Spinner color="dark" type="grow" className="mr-2" size="sm" />
                        <Spinner color="dark" type="grow" className="mr-2" size="sm" />
                      </div>)
                    }
                    <div className="text-center mt-3 button-wrapper">
                      <Row>
                        <Col md="6">
                          <Button color="primary" size="lg" className="sign-up-btn">
                            {t('signUp')}
                          </Button>
                        </Col>

                        <Col md="6">
                          <Link className="button-link" to="/">
                            <Button color="primary" size="lg" outline>
                              {t('signIn')}
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignUp;
