import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Spinner
} from "reactstrap";
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import { AvForm, AvField } from 'availity-reactstrap-validation';
import logo from "../../assets/logo.svg";
import { useTranslation } from 'react-i18next';
import { useInput } from '../../hooks/input-hook';
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import { PasswordRegex } from "../../common/helpers";

const ResetPassword = (props) => {
  let history = useHistory();
  const [hasError, setErrors] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [token, setToken] = useState(props.match.params.verifyToken);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [spinnerEnable, setSpinnerEnable] = useState(false);
  const { value: newPassword, bind: bindPassword } = useInput('');
  const [errorStatus, setErrorStatus] = useState(false);
  const [responseFields, setResponseFields] = useState( // eslint-disable-line
    { message: 'Error occured', status: false }
  );

  const passwordFormat = () => {
    return (
      <p className="password-exclamation" >
        <span data-tip={t("passwordValidateMsg")}
        >
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="align-middle"
          />
        </span>
        <ReactTooltip className="doc-upload-tooltip" />
      </p>
    )
  }


  // eslint-disable-next-line
  useEffect(() => {
    if (pageLoading) {
      const requestToken = {
        token: token,
      };
      setPageLoading(false);
      axios.post(API_ENDPOINT + '/user/forgot-password/check-token', requestToken)
        .then(response => {
          if (response.status === 202) {
            setToken(response.data.data.token);
            setErrors(false);
          } else {
            setErrors(true);
          }
        }).catch(function () {
          setErrors(true);
        });
    }
  }
  );

  const handleSubmit = event => {
    const passwordRegX = PasswordRegex;   // eslint-disable-line

    setSpinnerEnable(!spinnerEnable);

    const request = {
      token: token,
      newPassword: newPassword,
    };
    if (passwordRegX.test(newPassword) === false) {
      toastr.error(t("passwordValidateMsg"))
    } else {
      axios.post(API_ENDPOINT + '/user/forgot-password/update-password', request)
        .then(response => {
          if (response.status === 202) {
            setErrorStatus(!errorStatus);
            responseFields.message = response.data.title;
            toastr.success(
              responseFields.message
            )
            setTimeout(() => {
              history.push({
                pathname: '/'
              })
            }, 2000);
            responseFields.status = true
            setSpinnerEnable(false);
          }
        })
        .catch(function (error) {
          setErrorStatus(!errorStatus);
          const errorMsg = (error && error.response && error.response.data && error.response.data.message) || (error && error.response && error.response.statusText);
          setErrorStatus(true);
          responseFields.message = errorMsg || 'Error Occured';
          toastr.error(
            errorMsg
          )
          responseFields.status = false
          setSpinnerEnable(false);
        });

    }
  }


  const { t } = useTranslation();
  return (
    <>
      <div className="main h-100 w-100">
        <div className="container h-100">
          <div className="login-wrapper">
            <Card>
              <CardBody className="card-body">
                <div className="m-sm-4">
                  <div className="text-center image-wrapper ">
                    <img
                      src={logo}
                      alt={t('altTextAppLogo')}
                      className="img-fluid"
                      width="225"
                    />
                  </div>

                  {hasError ? (
                    <>
                      <p>
                        Sorry, the reset password link seems to be invalid or expired. Please resend a new reset password request by <Link to="/" >
                          clicking here
                        </Link>
                      </p>
                      <p>
                        If you have any questions, just reply to this email—we're always happy to help out.
                      </p>
                      <p>Cheers,<br />Equipo Team</p>
                    </>
                  ) :
                    (
                      <>
                        <AvForm onValidSubmit={handleSubmit}>
                          <FormGroup>
                            <Label>
                              <div >
                                {t('newPassword')} {passwordFormat()}
                              </div>
                            </Label>
                            <AvField
                              bsSize="lg"
                              name="nwpassword"
                              type="password"
                              placeholder={t('enterPasswordPH')}
                              errorMessage={t('passwordErrorMessage')}
                              required />
                            <Label>{t('confirmPassword')} </Label>
                            <AvField
                              bsSize="lg"
                              name="confirmpassword"
                              type="password"
                              placeholder={t('enterPasswordPH')}
                              errorMessage={t('confirmPasswordErrorMessage')}
                              validate={{ match: { value: 'nwpassword' } }}
                              {...bindPassword}
                              required />
                            {spinnerEnable &&
                              (<div className="spinner-wrapper">
                                <Spinner color="dark" type="grow" className="mr-2" size="sm" />
                                <Spinner color="dark" type="grow" className="mr-2" size="sm" />
                                <Spinner color="dark" type="grow" className="mr-2" size="sm" />
                              </div>)
                            }
                          </FormGroup>
                          <div className="text-center mt-3 button-wrapper">
                            <Button color="primary" size="lg">
                              {t('save')}
                            </Button>
                          </div>
                          <Link className="button-link" to="/">
                            <Button color="primary" size="lg" autoFocus>
                              {t('back')}
                            </Button>
                          </Link>
                        </AvForm>
                      </>
                    )
                  }
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword;
