import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toastr } from "react-redux-toastr";
import Axios from "axios";
import Select from "react-select";
import * as Validation from "./../../../common/FieldValidation";
import { cookieService } from '../../../services/cookieService';

import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

const MedicationInput = (props) => {
  const [Drugs, setDrugs] = useState([]);

  const [DrugValue, setDrugValue] = useState("");
  // let dependentID = cookieService.get('dependentID');

  let DrugList = [];
  if (DrugValue.length >= 3) {
    DrugList = Drugs.map((key) => {
      return { value: key.id, label: key.drugName + "(" + key.code + ")" };
    });
  }


  const [Drug, setDrug] = useState({
    value: "",
    label: "Type here to search for a drug",
  });



  const [Dosage, setDosage] = useState("");
  const [Frequency, setFrequency] = useState("");
  const [Route, setRoute] = useState("");
  const [StartDate, setStartDate] = useState(new Date()); // eslint-disable-line
  const [EndDate, setEndDate] = useState(new Date()); // eslint-disable-line
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const fetchDrugs = () => {
    Axios.post(API_ENDPOINT + "/common/medication-search", {
      drugName: DrugValue,
    })
      .then((response) => {
        if (response.status === 200) {
          setDrugs(response.data.data);
        }
      })
      .catch((error) => { });
  };

  const setDrugMap = (key) => {
    setDrug({
      value: key.id,
      label: key.drugName + "(" + key.code + ")",
    });
  };

  const DrugHandler = (value) => {
    setDrug(value);
    if (Drugs.length > 0) {
      Drugs.filter((key) => key.id == value).map((filterdrug) => // eslint-disable-line
        setDrugMap(filterdrug)
      );
    }
  };

  const DosageHandler = (value) => {
    Validation.Dosage(value, setDosage, 50)
  };
  const FrequencyHandler = (value) => {
    Validation.Frequency(value, setFrequency, 50)
  };
  const RouteHandler = (value) => {
    Validation.Route(value, setRoute, 50)
  };
  // const StartDateHandler = (date) => {
  //   setStartDate(date);
  // };
  // const EndDateHandler = (date) => {
  //   setEndDate(date);
  // };

  const submitMedicationForm = () => {
    const fromToSubmit = {
      drugId: Drug.value,
      dosage: Dosage,
      frequency: Frequency,
      route: Route,
      administerStartDate: StartDate,
      administerEndDate: EndDate,
      // dependentID: dependentID ? dependentID : ''
    };

    Axios.post(
      API_ENDPOINT + "/patient/set-patient-medication",
      fromToSubmit
    )
      .then((res) => {
        if (res.status === 200) {
          toastr.success("Medication Added", "Medication Added Successfully");
          props.toggleModal();
          props.TableRenderMedications();
        }
      })
      .catch((error) => {
        toastr.error("error occurred", "Failed to create new Medication");
      });
  };

  let SubmitButton = "";

  if (Drug?.value == "") { // eslint-disable-line
    SubmitButton = (
      <Button color="primary" disabled onClick={() => submitMedicationForm()}>
        Save
      </Button>
    );
  } else {
    SubmitButton = (
      <Button color="primary" onClick={() => submitMedicationForm()}>
        Save
      </Button>
    );
  }

  useEffect(() => {
    if (DrugValue.length >= 3)
      fetchDrugs();
  }, [DrugValue]); // eslint-disable-line

  return (
    <div className="medication_info">
      {/* <Row>
        <Col md="12">
        <div className="ModalHeader"><h3>Add Medication</h3></div>
        </Col>
      </Row> */}
      <Row>
        <Col md="6">
          <Label>Drug Name{" "}<span className="mandatory-marker">*</span></Label>
          <FormGroup>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={DrugList}
              onInputChange={(event) => setDrugValue(event)}
              onChange={(event) => DrugHandler(event)}
              value={Drug}
              isSearchable
              isClearable
              placeholder="Type here to search for a drug"
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <Label>Dosage{" "}</Label>
          <FormGroup>
            <Input
              type="text"
              name="dosage"
              value={Dosage}
              onChange={(event) => {
                DosageHandler(event.target.value);
              }}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <Label>Frequency{" "}</Label>
          <FormGroup>
            <Input
              type="text"
              name="frequency"
              value={Frequency}
              onChange={(event) => {
                FrequencyHandler(event.target.value);
              }}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <Label>Route{" "}</Label>
          <FormGroup>
            <Input
              type="text"
              name="route"
              value={Route}
              onChange={(event) => {
                RouteHandler(event.target.value);
              }}
            />
          </FormGroup>
        </Col>
        {/* <Col md="6">
          <FormGroup className="bordered">
            <Label>Administer Start Date{" "}<span className="mandatory-marker">*</span></Label>
            <DatePicker
              dateFormat="dd MMM yyyy"
              placeholderText="Select date"
              selected={StartDate}
              onChange={(date) => StartDateHandler(date)}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup className="bordered">
            <Label>Administer End Date{" "}<span className="mandatory-marker">*</span></Label>
            <DatePicker
              dateFormat="dd MMM yyyy"
              placeholderText="Select date"
              selected={EndDate}
              onChange={(date) => EndDateHandler(date)}
            />
          </FormGroup>
        </Col> */}
      </Row>
      <Row className="row" style={{ 'justifyContent': 'center' }}>
        {SubmitButton}
        <Button color="outline-primary ml-2" onClick={props.toggleModal}>
          Cancel
        </Button>

      </Row>
    </div>
  );
};
export default MedicationInput;
