import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload,faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalBody } from "reactstrap";
import AddDocument from "./AddDocument"
import ModalHeader from "reactstrap/lib/ModalHeader"; // eslint-disable-line
import "../Identification/Identification.css"


const colors = [
  {
    name: "Primary",
    value: "primary",
  },
];

const AddDocumentModal = (props) => {
  const initOpenModals = () => {
    let modals = {};

    colors.forEach((color, index) => {
      modals = Object.assign({}, modals, { [index]: false });
    });

    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());

  const toggle = (index) => {
    // Toggle selected element
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };


  const ToggleHandler =(index)=>{
    toggle(index)
  }

  return (
    <>
      {colors.map((color, index) => (
        <React.Fragment key={index}>
          <Button
            color="primary"
            size="sm"
            className="pull-right d-flex align-items-center justify-content-center mr-2"
            onClick={() => toggle(index)}
          >
            <FontAwesomeIcon
              icon={faUpload}
              fixedWidth
              className="align-middle mr-2"
            />
            Upload
          </Button>

          <Modal
            isOpen={openModals[index]}
            toggle={() => toggle(index)}
            centered
          >

            {/* <ModalHeader ><h3 className="header-center">Add Document</h3></ModalHeader> */}
            <h3 className="ModalHeader mt-3">Add Document</h3>
            <div
              className="upload-modal-close"
              onClick={() => toggle(index)}
            >
              <FontAwesomeIcon size={"sm"} icon={faTimesCircle} />
            </div>
            <div className="modal-border"></div>
            <ModalBody className=" m-3"><AddDocument ToggleModal={()=>ToggleHandler(index)} RenderDocuments={props.RenderDocuments} setNoDataFound={props.setNoDataFound} /></ModalBody>
            
          </Modal>
        </React.Fragment>
      ))}
    </>
  );
};

export default AddDocumentModal;
