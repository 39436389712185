import React, { useState } from "react";
import DependentsInput from "./DependentsInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalBody } from "reactstrap";

const colors = [
  {
    name: "Primary",
    value: "primary",
  },
];

const DependentsModal = (props) => {

  const initOpenModals = () => {
    let modals = {};

    colors.forEach((color, index) => {
      modals = Object.assign({}, modals, { [index]: false });
    });

    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());

  const toggle = (index) => {
    // Toggle selected element
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };

  return (
    <>
      {colors.map((color, index) => (
        <React.Fragment key={color.value}>
          {props.job === "edit" ? (
            <Button className="outline-primary ml-2 mb-0 delete-table-row" title="Edit dependent details" onClick={(e) => { toggle(index); e.stopPropagation(); }}>
              <FontAwesomeIcon
                icon={faEdit}
                fixedWidth
                className="align-middle mr-2 "
                title="Edit dependent details"
              />
            </Button>
          ) : (
            <Button color="primary ml-4" onClick={() => toggle(index)}>
              <FontAwesomeIcon
                icon={faPlus}
                fixedWidth
                className="align-middle mr-2"
              />
              Add Dependent
            </Button>
          )}

          <Modal
            isOpen={openModals[index]}
            // toggle={() => toggle(index)}
            centered
            className="add-dependent"
          >

            {props.job === "edit" ? <h3 className="ModalHeader mt-3">Edit Dependent</h3> : <h3 className="ModalHeader mt-3">Add Dependent</h3>}

            <div
              className="vital-modal-close"
              onClick={() => toggle(index)}
            >
              <FontAwesomeIcon size={"sm"} icon={faTimesCircle} />
            </div>
            <div className="modal-border mb-3"></div>
            <ModalBody className=" m-3 PhysiciansModal">
              {props.job === "edit" ?
                <DependentsInput
                  job={props.job}
                  toggleModal={() => toggle(index)}
                  TableRenderPhysicians={props.TableRenderPhysicians}
                  dependent_id={props.dependent_id}

                /> : <DependentsInput

                  job={props.job}
                  toggleModal={() => toggle(index)}
                  TableRenderPhysicians={props.TableRenderPhysicians}
                />
              }

            </ModalBody>
          </Modal>
        </React.Fragment>
      ))}
    </>
  );
};

export default DependentsModal;
