import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toastr } from "react-redux-toastr";
import Axios from "axios";
import Select from "react-select";
import * as Validation from "./../../../common/FieldValidation";
import { cookieService } from '../../../services/cookieService';

import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

const ImmunizationInput = (props) => {
  const [Drugs, setDrugs] = useState([]);
  const [immValue, setImmuValue] = useState("");

  // let dependentID = cookieService.get('dependentID');
  const [immunizationValue, setImmunizationValue] = useState({
    value: "",
    label: "Search for options",
  });

  let immunizationList = [];
  if (immValue.length >= 3) {
    immunizationList = Drugs.map((key) => {
      return { value: key.id, label: key.drugName + "(" + key.code + ")" };
    });
  }
  const SiteList = [
    { value: 1, label: 'Arm' },
    { value: 2, label: 'Buttock' },
    { value: 3, label: 'Other' }
  ];


  const [site, setSite] = useState({
    value: "",
    label: "Select option",
  });



  const [cvxCode, setCvxCode] = useState("");
  const [Manufacturer, setManufacturer] = useState("");
  const [LotNumber, setLotNumber] = useState("");
  const [Unit, setUnit] = useState("");
  const [Amount, setAmount] = useState("");
  const [StartDate, setStartDate] = useState(new Date()); // eslint-disable-line
  const [EndDate, setEndDate] = useState(new Date()); // eslint-disable-line
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const fetchDrugs = () => {
    Axios.post(API_ENDPOINT + "/common/immunization-search", {
      drugName: immValue,
    })
      .then((response) => {
        if (response.status === 200) {
          setDrugs(response.data.data);
        }
      })
      .catch((error) => { });
  };

  const setImmunizationMap = (key) => {
    setImmunizationValue({
      value: key.id,
      label: key.drugName + "(" + key.code + ")",
    });
  };

  const ImmunizationHandler = (value) => {
    debugger
    setImmunizationValue(value);
    if (Drugs.length > 0) {
      Drugs.filter((key) => key.id == value).map((filterImmunization) => // eslint-disable-line
        setImmunizationMap(filterImmunization)
      );
    }
  };

  //   const setUnit = (value) => {
  //     Validation.Unit(value,setUnit,50)
  //   };
  //   const setManufacturer = (value) => {
  //     Validation.Manufacturer(value,setManufacturer,50)
  //   };
  //   const setLotNumber = (value) => {
  //     Validation.LotNumber(value,setLotNumber,50)
  //   };
  const StartDateHandler = (date) => {
    setStartDate(date);
  };
  const EndDateHandler = (date) => {
    setEndDate(date);
  };

  const submitImmunizationForm = () => {
    const fromToSubmit = {
      drugId: immunizationValue.value,
      cvxCode: cvxCode,
      manufacturer: Manufacturer,
      route: LotNumber,
      lotNumber: LotNumber,
      administeredDate: StartDate,
      appointmentId: 1234,
      administrationSite: site.label,
      educationDate: "",
      amountAdministered: Amount,
      amountAdministeredUnit: Unit,
      expirationDate: "",
      immunizationStatus: "",
      comment: "",
      administeredByID: 123


      //   administerEndDate: EndDate,
      // dependentID: dependentID ? dependentID : ''
    };

    Axios.post(
      API_ENDPOINT + "/patient/set-patient-immunization",
      fromToSubmit
    )
      .then((res) => {
        if (res.status === 200) {
          toastr.success("Immunization Added", "Immunization Added Successfully");
          props.toggleModal();
          props.TableRenderImmunizations();
        }
      })
      .catch((error) => {
        toastr.error("error occurred", "Failed to create new Immunization");
      });
  };

  let SubmitButton = "";

  if (immunizationValue?.value == "") { // eslint-disable-line
    SubmitButton = (
      <Button color="primary" disabled onClick={() => submitImmunizationForm()}>
        Save
      </Button>
    );
  } else {
    SubmitButton = (
      <Button color="primary" onClick={() => submitImmunizationForm()}>
        Save
      </Button>
    );
  }

  useEffect(() => {
    if (immValue.length >= 3)
      fetchDrugs();
  }, [immValue]); // eslint-disable-line

  return (
    <div className="medication_info">
      {/* <Row>
        <Col md="12">
        <div className="ModalHeader"><h3>Add Immunization</h3></div>
        </Col>
      </Row> */}
      <Row>
        <Col md="6">
          <Label>CVX Code{" "}<span className="mandatory-marker">*</span></Label>
          <FormGroup>
            <Input
              type="text"
              name="cvxCode"
              placeholder="CVX Code"
              value={cvxCode}
              onChange={(event) => {
                setCvxCode(event.target.value);
              }}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <Label>Immunization{" "}</Label>
          <FormGroup>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={immunizationList}
              onInputChange={(event) => setImmuValue(event)}
              onChange={(event) => ImmunizationHandler(event)}
              value={immunizationValue}
              isSearchable
              isClearable
              placeholder="Select option"
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <Label>Manufacturer{" "}</Label>
          <FormGroup>
            <Input
              type="text"
              name="manufacturer"
              placeholder="Manufacturer"
              value={Manufacturer}
              onChange={(event) => {
                setManufacturer(event.target.value);
              }}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <Label>Lot Number{" "}</Label>
          <FormGroup>
            <Input
              type="text"
              name="lotNumber"
              placeholder="Lot Number"
              value={LotNumber}
              onChange={(event) => {
                setLotNumber(event.target.value);
              }}
            />
          </FormGroup>
        </Col>

        <Col md="6">
          <Label>Unit{" "}</Label>
          <FormGroup>
            <Input
              type="text"
              name="unit"
              placeholder="Unit"
              value={Unit}
              onChange={(event) => {
                setUnit(event.target.value);
              }}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <Label>Amount{" "}</Label>
          <FormGroup>
            <Input
              type="text"
              name="amount"
              placeholder="Amount"
              value={Amount}
              onChange={(event) => {
                setAmount(event.target.value);
              }}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <Label>Site{" "}<span className="mandatory-marker">*</span></Label>
          <FormGroup>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={SiteList}
              //   onInputChange={(event)=>setDrugValue(event)}
              onChange={(event) => setSite(event)}
              value={site}
              isSearchable
              isClearable
              placeholder="Select option"
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup className="bordered">
            <Label>Administered Date{" "}<span className="mandatory-marker">*</span></Label>
            <DatePicker
              dateFormat="dd MMM yyyy"
              placeholderText="Select date"
              selected={StartDate}
              onChange={(date) => StartDateHandler(date)}
            />
          </FormGroup>
        </Col>
        {/* <Col md="6">
          <FormGroup className="bordered">
            <Label>Administer Start Date{" "}<span className="mandatory-marker">*</span></Label>
            <DatePicker
              dateFormat="dd MMM yyyy"
              placeholderText="Select date"
              selected={StartDate}
              onChange={(date) => StartDateHandler(date)}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup className="bordered">
            <Label>Administer End Date{" "}<span className="mandatory-marker">*</span></Label>
            <DatePicker
              dateFormat="dd MMM yyyy"
              placeholderText="Select date"
              selected={EndDate}
              onChange={(date) => EndDateHandler(date)}
            />
          </FormGroup>
        </Col> */}
      </Row>
      <Row className="row" style={{ 'justifyContent': 'center' }}>
        {SubmitButton}
        <Button color="outline-primary ml-2" onClick={props.toggleModal}>
          Cancel
        </Button>

      </Row>
    </div>
  );
};
export default ImmunizationInput;
