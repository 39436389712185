import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Carousel, { consts } from 'react-elastic-carousel';
import Select from "react-select";
import axios from 'axios';
import { AvForm } from 'availity-reactstrap-validation';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import getSymbolFromCurrency from 'currency-symbol-map';
import { GlobalState } from "../../context/ContextApi";
import {
  Card,
  CardBody,
  Col,
  Input,
  Row,
  Button,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";

import {
  faCalendar,
  faSearch,
  faChevronLeft,
  faChevronRight,
  faUserNurse,
  faUsers,
  faStar,
  faMapPin
} from "@fortawesome/free-solid-svg-icons";

import Header from "../Layout/Header/Header";
import HeaderTitle from "../Layout/Header/HeaderTitle";
import PageLayout from "../Layout/PageLayout/PageLayout";
import { useTranslation } from "react-i18next";
import "./Booking.css";
import styles from "./Booking.module.css";
import avatar from "../../assets/avatar.png";
import ProfileModal from "./ProfileModal";
import CountryConfirmationModal from "./CountryConfirmationModal";


const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const Booking = (props) => {
  let history = useHistory();
  // CONTEXT VARIABLES 
  const {
    Details, userId,
    physicianSearchDetails, setPhysicianSearchDetails,
    specialityDetailsContext, setSpecialityDetailsContext,
    searchedSpeciality, setSearchedSpeciality
  } = useContext(GlobalState);

  // LOCAL VARIABLES 
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState("");
  const [slot, setSlot] = useState("");
  const [physician, setPhysician] = useState("");
  const [States, setStates] = useState([])
  const [UserState, setUserState] = useState('')
  const [isDetailsReceived, setIsDetailsReceived] = useState(false)
  const [isClear, setIsClear] = useState(false)

  const [Countries, setCountries] = useState([])
  const [UserCountry, setUserCountry] = useState('')
  const [CountryID, setCountryID] = useState("");

  const [startDate, setStartDate] = useState(new Date());
  const [specialityData, setSpecialityData] = useState([]);
  const [triggeredPhysicianSearch, setTriggeredPhysicianSearch] = useState(false);
  const [selectedspeciality, setselectedspeciality] = useState('none');
  const [selectedUserType, setSelectedUserType] = useState(3);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [searchPhysicianResults, setsearchPhysicianResults] = useState([]);
  const [timeslot, settimeslot] = useState([]);
  const [clearFilterClicked, setClearFilterClicked] = useState(false);
  const [carouselDays, setCarouselDays] = useState([]);
  const [sliderCount, setSliderCount] = useState(3);
  const [spinnerEnable, setSpinnerEnable] = useState(false);
  const [isSpecialitySelectOpen, setIsSpecialitySelectOpen] = useState(false);

  const [noResult, setNoResult] = useState(false); // eslint-disable-line
  const startDay = moment(); // eslint-disable-line
  const endDay = moment().add(14, 'days'); // eslint-disable-line
  const doctorNameInput = useRef(null);
  let searchPhysicianResultsArr = [];

  let specialityOptions = specialityData.map(function (list) {
    return { value: list.id, label: list.specialityName };
  })

  let userTypeOptions = [{
    value: 3,
    label: "Doctor",
  }, {
    value: 6,
    label: "Nurse",
  }, {
    value: 7,
    label: "Therapist",
  }, {
    value: 8,
    label: "Carer",
  }
  ];

  const setSlotData = (physicianResults) => {
    let mappedLocalAvailableTime = {};
    physicianResults.forEach(physician => {
      mappedLocalAvailableTime[physician.id] = {};
      if (physician.physicianData["0"].slots.length > 0) {
        try {
          physician.physicianData["0"].slots.forEach(slot => {
            let workingHours = JSON.parse(JSON.stringify(slot));
            updateAvailableSlotsToLocalTime(workingHours, mappedLocalAvailableTime[physician.id]);
          })
        } catch (e) { }
      }
    });
    /* Set Dummy Slots */
    Object.keys(mappedLocalAvailableTime).map(function (physicianId) { // eslint-disable-line
      let maxLength = 0;
      Object.keys(mappedLocalAvailableTime[physicianId]).map(date => { // eslint-disable-line
        if (mappedLocalAvailableTime[physicianId][date].length > maxLength) {
          maxLength = mappedLocalAvailableTime[physicianId][date].length;
        }
      });
      Object.keys(mappedLocalAvailableTime[physicianId]).map(date => { // eslint-disable-line
        if (mappedLocalAvailableTime[physicianId][date].length < maxLength) {
          var dummySlots = [];
          for (let i = mappedLocalAvailableTime[physicianId][date].length; i < maxLength; i++) {
            dummySlots.push({ status: "Unavailable", localTime: "-" })
          }
          mappedLocalAvailableTime[physicianId][date] = mappedLocalAvailableTime[physicianId][date].concat(dummySlots);
        }
      });
    });
    settimeslot(mappedLocalAvailableTime);
  }

  const updateAvailableSlotsToLocalTime = (workingHours, availableLocalTimeSlots) => {
    let timeStartSplit = workingHours.startAt.split(" ")[1].split(":");
    let timeEndSplit = workingHours.endAt.split(" ")[1].split(":");
    let startTime = new Date(new Date(workingHours.startAt.split(" ")[0]).setUTCHours(timeStartSplit[0], timeStartSplit[1]));
    let endTime = new Date(new Date(workingHours.endAt.split(" ")[0]).setUTCHours(timeEndSplit[0], timeEndSplit[1]));
    let startLocalYear = startTime.getFullYear();
    let startLocalMonth = startTime.getMonth() + 1;
    startLocalMonth = startLocalMonth < 10 ? "0" + startLocalMonth : "" + startLocalMonth;
    let startLocalDate = startTime.getDate();
    startLocalDate = startLocalDate < 10 ? "0" + startLocalDate : "" + startLocalDate;
    let startLocalHr = startTime.getHours();
    startLocalHr = startLocalHr < 10 ? "0" + startLocalHr : "" + startLocalHr;
    let startLocalMin = startTime.getMinutes();
    startLocalMin = startLocalMin < 10 ? "0" + startLocalMin : "" + startLocalMin;

    let endLocalMonth = endTime.getMonth() + 1; // eslint-disable-line
    endLocalMonth = endLocalMonth < 10 ? "0" + endLocalMonth : "" + endLocalMonth;
    let endLocalDate = endTime.getDate(); // eslint-disable-line
    endLocalDate = endLocalDate < 10 ? "0" + endLocalDate : "" + endLocalDate;
    let endLocalHr = endTime.getHours(); // eslint-disable-line
    endLocalHr = endLocalHr < 10 ? "0" + endLocalHr : "" + endLocalHr;
    let endLocalMin = endTime.getMinutes(); // eslint-disable-line
    endLocalMin = endLocalMin < 10 ? "0" + endLocalMin : "" + endLocalMin;
    let localDate = startLocalYear + "-" + startLocalMonth + "-" + startLocalDate;
    workingHours.localTime = convertTo12Hour(startLocalHr + ":" + startLocalMin);
    if (availableLocalTimeSlots[localDate]) {
      availableLocalTimeSlots[localDate].push(workingHours);
    } else {
      availableLocalTimeSlots[localDate] = [workingHours]
    }
  }

  const getSpecialityName = (specialityID) => {
    if (specialityData && specialityData.length > 0) {
      let filteredSpeciality = specialityData.filter(speciality => speciality.id == searchedSpeciality); // eslint-disable-line
      if (filteredSpeciality.length > 0) {
        return filteredSpeciality[0].specialityName || "";
      } else {
        return "";
      }
    }
    return "";
  }

  const convertTo12Hour = (time) => {
    var hours = parseInt(time.substr(0, 2));
    var minutes = parseInt(time.substr(3, 2));
    var timeMedian = hours < 12 ? "AM" : "PM";
    hours = hours > 12 ? hours - 12 : hours;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes === 0 ? "00" : minutes;
    return hours + ":" + minutes + " " + timeMedian;
  }

  useEffect(() => {
    if (sliderEleRef && sliderEleRef.current) {
      let calendarIndex = Number(moment(new Date(startDate)).startOf('day').diff(moment().startOf('day'), 'days'));
      let collection = document.getElementsByClassName('rightButton');
      let collectionReset = document.getElementsByClassName('leftButton');
      setTimeout(function () {
        for (let i = 0; i < 15; i++) {
          for (let item of collectionReset) {
            item.click();
          }
        }

        for (let i = 0; i < calendarIndex; i++) {
          for (let item of collection) {
            item.click();
          }
        }
        sliderCalenRef.current.goTo(calendarIndex);
      });
    }
  }, [timeslot]); // eslint-disable-line

  useEffect(() => {
    if (selectedspeciality !== "none" && !triggeredPhysicianSearch && !(physicianSearchDetails && physicianSearchDetails.responseData)) {
      setTriggeredPhysicianSearch(true);
      handlePhysicianSearch();
    }
  }, [selectedspeciality]); // eslint-disable-line

  useEffect(() => {
    if (selectedUserType && userId) {
      let daysList = [];
      for (let i = 0; i <= 15; i++) {
        let day = moment().add(i, 'days').format('ddd');
        let date = moment().add(i, 'days').format('DD MMM YYYY');
        let formattedDate = moment().add(i, 'days').format('YYYY-MM-DD');
        daysList.push({ day, date, formattedDate });
      }
      setCarouselDays(daysList);
      if (specialityDetailsContext && specialityDetailsContext.length > 0 && specialityDetailsContext[0].type == selectedUserType) { // eslint-disable-line
        setSpecialityData(specialityDetailsContext);
        let speciality = 0
        specialityDetailsContext.filter(item => { // eslint-disable-line
          if (item.specialityName === "General Practitioner") {
            speciality = item.id
          }
        })
        speciality ? setselectedspeciality(speciality) : setselectedspeciality(specialityDetailsContext[0].id);
        return;
      }
      setSpecialityDetailsContext([]);
      axios.post(`speciality/get-speciality-list/${selectedUserType}/${userId}`)
        .then(function (response) {
          if (response.status === 202) {
            setSpecialityDetailsContext(response.data.data);
            setSpecialityData(response.data.data);
            let speciality = 0
            if (response.data.data && response.data.data.length > 0) {
              response.data.data.filter(item => { // eslint-disable-line
                if (item.specialityName === "General Practitioner") {
                  speciality = item.id
                }
              })
              if (speciality) {
                setselectedspeciality(speciality)
              } else {
                setselectedspeciality(response.data.data[0].id);
              }
            }
          }
        })
        .catch(function (error) {
          // 
        });
    }
  }, [selectedUserType, userId, clearFilterClicked]); // eslint-disable-line

  const [slideConfig] = useState({
    totalSlide: 0,
    sliderWidth: 0,
    sliderEachSlideWidth: 165,
    count: 0
  })

  const handlePhysicianSearch = (isClear) => {
    if (selectedspeciality == 'none') { // eslint-disable-line
      setTriggeredPhysicianSearch(true);
      return;
    }
    setSearchTriggered(true);
    handleSubmit(isClear);
  }

  const handleChange = date => {
    setStartDate(date);
  };

  const handleInputChange = event => {
    setselectedspeciality(event.value);
  };

  const handleUserTypeChange = event => {
    setSelectedUserType(event.value);
    setselectedspeciality("none");
  }

  const onMenuOpen = () => {
    setIsSpecialitySelectOpen(true);
  }

  const onMenuClose = () => {
    setIsSpecialitySelectOpen(false);
  }

  const { t } = useTranslation();
  const sliderWrapRef = useRef(null);
  const sliderCalenRef = useRef(null);
  const sliderEleRef = useRef(null);

  const getWindowWidth = () => {
    const getWidth = window.innerWidth;
    if (getWidth <= 1100 && getWidth > 425) {
      setSliderCount(2);
    } else if (getWidth < 425) {
      setSliderCount(1);
    }
  };


  useEffect(() => {
    if (sliderWrapRef.current) {
      slideConfig.sliderWidth = sliderWrapRef.current.offsetWidth;
      slideConfig.totalSlide = document.getElementsByClassName('pat-time-slot').length;
      let totalWidth = slideConfig.sliderWidth * slideConfig.totalSlide;
      document.getElementsByClassName('pat-time-row')[0].style.width = totalWidth + 'px';
      let collection = document.getElementsByClassName('pat-time-row');
      for (let item of collection) {
        item.style.width = totalWidth + 'px';
      }
    }
    getWindowWidth();
    const handleResize = () => {
      getWindowWidth();
    }
    try {
      window.removeEventListener('resize', handleResize)
      window.addEventListener('resize', handleResize)
    } catch (e) { }

    if (Details.is_patient) {
      setIsDetailsReceived(true)
    }
  })

  useEffect(() => {
    if (Details.is_patient) {
      // Setting User's State Manually
      let country = Details?.is_patient.countryID;
      setUserState(Details?.is_patient?.stateID)
      if (CountryID && isClear === false) {
        setUserState('')
        setStates([])
        country = CountryID;
      }
      axios.get(`common/state-list/${country}`)
        .then(
          response => {
            const StatesList = response.data.data.map((item) => {
              // return { value: item.shortCode, label: item.stateName }
              if (country === '223')
                return { value: item.shortCode, label: item.stateName }
              else if (country === '103')
                return { value: item.countyCode, label: item.countyName }
            });
            setStates(StatesList)
          })
        .catch(error => {
          console.log("Error Loading state List", error);
        });
    }
    setIsClear(false);
  }, [isDetailsReceived, isClear, CountryID]);

  useEffect(() => {

    // setUserState("")
    setUserCountry(Details?.is_patient?.countryID)
    axios.get(`common/country-list`)
      .then(
        response => {
          const CountryList = response.data.data.map((item) => {
            return { value: item.id, label: item.country_name }
          });
          setCountries(CountryList)
        })
      .catch(error => {
        console.log("Error Loading Country List", error);
      });
  }, [isDetailsReceived])

  // const onChangeList = (event, errors, values) => {
  //   // event.id==='223' ? setZipText('Zip Code') : setZipText('Post Code');
  //   setUserCountry(event.value);
  //   setCountryID(event.value);
  //   // if (Countries.length > 0) {
  //   //   Countries
  //   //     .filter((key) => key.country_name == event.value) // eslint-disable-line
  //   //     .map((filterCountry) => {setCountryID(filterCountry);console.log(filterCountry)});
  //   // }
  // };



  useEffect(() => {
    if (physicianSearchDetails && physicianSearchDetails.responseData) {
      setSelectedUserType(physicianSearchDetails.userType)
      setStartDate(physicianSearchDetails.date);
      setselectedspeciality(physicianSearchDetails.speciality);
      setSearchedSpeciality(physicianSearchDetails.speciality);
      doctorNameInput.current.value = physicianSearchDetails.name || '';
      setUserState(physicianSearchDetails.state)
      setSearchTriggered(true);
      setSearchResults(physicianSearchDetails.responseData);
    }
  }, [physicianSearchDetails]) // eslint-disable-line


  const onNextHandler = () => {
    let collection = document.getElementsByClassName('rightButton');
    for (let item of collection) {
      item.click();
    }
  }

  const onPrevHandler = () => {
    let collection = document.getElementsByClassName('leftButton');
    for (let item of collection) {
      item.click();
    }
  }

  const getUTCDates = () => {
    let timeSlots = [];
    let utcStartTime = moment().utc();
    let utcEndTime = moment().add(16, "days").endOf('day').utc();
    if (utcEndTime.diff(utcStartTime, 'days') > 1) {
      while (utcEndTime.diff(utcStartTime, 'days') > 1) {
        timeSlots.push({ "startAt": utcStartTime.format("YYYY-MM-DD HH:mm:ss"), "endAt": utcStartTime.endOf('day').format("YYYY-MM-DD HH:mm:ss") });
        utcStartTime = utcStartTime.add(1, 'days').startOf('day');
      }
      timeSlots.push({ "startAt": utcStartTime.format("YYYY-MM-DD HH:mm:ss"), "endAt": utcEndTime.format("YYYY-MM-DD HH:mm:ss") });
    } else {
      timeSlots.push({ "startAt": utcStartTime.format("YYYY-MM-DD HH:mm:ss"), "endAt": utcEndTime.format("YYYY-MM-DD HH:mm:ss") });
    }
    return JSON.stringify(timeSlots);
  }

  const handleSubmit = (isClear = false) => {
    setSpinnerEnable(true);
    searchPhysicianResultsArr = [];
    setsearchPhysicianResults([]);
    let doctorName = doctorNameInput.current.value.trim();
    let searchPayload = {
      searchDate: getUTCDates(),
      speciality: selectedspeciality,
      userType: selectedUserType,
      userState: UserState,
      userCountry: UserCountry
    };
    if (doctorName && doctorName !== '') {
      searchPayload.name = doctorName;
    }

    // Filter reset payload
    let searchResetPayload = {
      searchDate: getUTCDates(),
      speciality: "1",
      userType: 3,
      userState: Details?.is_patient?.stateID,
      userCountry: Details?.is_patient?.countryID,
      name: ""
    }

    setPhysicianSearchDetails({});
    let config = {
      method: 'post',
      url: API_ENDPOINT + '/physician/search',
      data: isClear ? searchResetPayload : searchPayload
    };
    axios(config)
      .then(function (response) {
        let res = response.data.data
        // console.log(response.data.data)
        let physicianData = {
          "name": doctorName,
          "state": UserState,
          "country": UserCountry,
          "speciality": selectedspeciality,
          "userType": selectedUserType,
          "date": startDate,
          "responseData": res
        }
        setPhysicianSearchDetails(physicianData);
        setSearchResults(res);
        setSpinnerEnable(false);
      })
      .catch(function (error) {
        searchPhysicianResultsArr = [];
        setsearchPhysicianResults([]);
        setSpinnerEnable(false);
      });

  }

  const clearFilters = () => {
    if (!spinnerEnable) {
      setSelectedUserType(3);
      setClearFilterClicked(!clearFilterClicked);
      doctorNameInput.current.value = '';
      setUserState(Details?.is_patient?.stateID)
      setUserCountry(Details?.is_patient?.countryID)
      setStartDate(new Date());
      setPhysicianSearchDetails({});
      setTriggeredPhysicianSearch(false);
      setIsClear(true);
      setCountryID('');
      // Reset results
      handlePhysicianSearch(true);
    }
  }

  const onSlotCheckout = (id, slot, physician) => {
    if (slot.status === "Available") {
      if (physician && physician.physicianData[0] && physician.physicianData[0].countryOfRegistrationOnly && Details && Details.is_patient && Details.is_patient.country && physician.physicianData[0].countryOfRegistrationOnly.indexOf(Details.is_patient.country) > -1) {
        proceedToCheckout(id, slot, physician);
      } else {
        setId(id);
        setSlot(slot);
        setPhysician(physician);
        setIsOpen(true);
      }
    }
  }

  const proceedToCheckout = (id, slot, physician, isDifferentCountry) => {
    let speciality = getSpecialityName(physician.physicianData["0"].speciality);
    history.push({
      pathname: '/slotCheckout',
      state: { detail: { slot, id, physician, speciality, isDifferentCountry } }
    })

  }

  function setSearchResults(response) {
    setSpinnerEnable(true);
    if (searchPhysicianResultsArr.length > 0) {
      let newDrList = [];
      searchPhysicianResultsArr = searchPhysicianResultsArr.map(physician => {
        let matchedPhysician = response.filter(singlePhysician => singlePhysician.id === physician.id);
        if (matchedPhysician.length) {
          physician.physicianData["0"].slots = physician.physicianData["0"].slots.concat(matchedPhysician[0].physicianData["0"].slots);
        }
        return physician;
      });
      response.forEach(physician => {
        let matchedPhysician = searchPhysicianResultsArr.filter(singlePhysician => singlePhysician.id === physician.id);
        if (matchedPhysician.length === 0) {
          newDrList.push(physician);
        }
      })

      response = searchPhysicianResultsArr.concat(newDrList);
    } else {
      setNoResult(true);
    }
    searchPhysicianResultsArr = response;
    setsearchPhysicianResults(response);
    setSlotData(response);
    setSpinnerEnable(false);
  }

  const showContentToggle = (event) => {
    const getCurrentTarget = event.currentTarget;
    if (getCurrentTarget.text === 'Show more') {
      getCurrentTarget.text = 'Show less';
      getCurrentTarget.parentElement.parentElement.children[0].classList.add("show-more");
    } else {
      getCurrentTarget.text = 'Show more';
      getCurrentTarget.parentElement.parentElement.children[0].classList.remove("show-more");
    }
  }

  return (
    <PageLayout>
      <Header>
        <HeaderTitle>{t('Book Appointment')}</HeaderTitle>
      </Header>
      <Row>
        <Col>
          <Card className={styles.providerCard}>
            <div className="header-buttons">
              <Link to="/my-appointments" style={{ padding: '0 1.25rem' }} className="pull-right">
                <button className="btn btn-primary pull-right">
                  My Appointments
                </button>
              </Link>
            </div>
            <CardBody className={styles.bookingCardBody}>
              <AvForm onValidSubmit={() => handlePhysicianSearch()}>
                <Row noGutters>
                  <Col lg={12} md={12}>
                    <div className="search-wrapper">
                      <div className="input-wrapper" title="Select Type">
                        <FontAwesomeIcon icon={faUsers} className="input-icon" />
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={userTypeOptions}
                          value={userTypeOptions.filter(option => option.value === selectedUserType)}
                          placeholder="Select Type"
                          onChange={handleUserTypeChange}
                          isSearchable
                        />
                      </div>
                      <div className="select-wrapper" title="Select Speciality">
                        <FontAwesomeIcon icon={faUserNurse} className="input-icon" />
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={specialityOptions}
                          value={specialityOptions.filter(option => option.value === selectedspeciality)}
                          placeholder="Select Speciality"
                          onChange={handleInputChange}
                          styles={{
                            control: styles => ({ ...styles, boxShadow: triggeredPhysicianSearch && selectedspeciality === 'none' && !isSpecialitySelectOpen ? 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(243, 11, 11, 0.25) !important' : 'none' })
                          }}
                          onMenuOpen={onMenuOpen}
                          onMenuClose={onMenuClose}
                          isSearchable
                        />
                        {triggeredPhysicianSearch && selectedspeciality === "none" && !isSpecialitySelectOpen ? (
                          <span className="speciality-err">Please select a speciality and search.</span>
                        ) :
                          null}
                      </div>
                      <div className="input-wrapper">
                        <FontAwesomeIcon icon={faSearch} className="input-icon" />
                        <Input innerRef={doctorNameInput} type="text" name="input" placeholder={t('Search by Name')} title="Enter name" />
                      </div>
                      {/* Adding Physician State Search */}
                      <div className="select-wrapper" title="Select Country">
                        <FontAwesomeIcon icon={faMapPin} className="input-icon" />
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={Countries}
                          value={Countries.filter(country => country.value == UserCountry)}
                          placeholder="Select Country"
                          onChange={(event) => { setUserCountry(event.value); setCountryID(event.value); }}
                          // onChange={onChangeList}
                          styles={{
                            control: styles => ({ ...styles, boxShadow: triggeredPhysicianSearch && selectedspeciality === 'none' && !isSpecialitySelectOpen ? 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(243, 11, 11, 0.25) !important' : 'none' })
                          }}
                          onMenuOpen={onMenuOpen}
                          onMenuClose={onMenuClose}
                          isSearchable
                        />
                      </div>
                      <div className="select-wrapper" title="Select State">
                        <FontAwesomeIcon icon={faMapPin} className="input-icon" />
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={States}
                          value={States.filter(state => state.value === UserState)}
                          placeholder="Select State"
                          onChange={(event) => setUserState(event.value)}
                          styles={{
                            control: styles => ({ ...styles, boxShadow: triggeredPhysicianSearch && selectedspeciality === 'none' && !isSpecialitySelectOpen ? 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(243, 11, 11, 0.25) !important' : 'none' })
                          }}
                          onMenuOpen={onMenuOpen}
                          onMenuClose={onMenuClose}
                          isSearchable
                        />
                      </div>
                      <div className="date-btn-container">
                        <div className="datepicker-wrapper">
                          <FontAwesomeIcon icon={faCalendar} className="datepicker-icon" />
                          <DatePicker
                            dateFormat="dd MMM yyyy"
                            minDate={moment().toDate()}
                            maxDate={moment().add(15, 'days').toDate()}
                            selected={startDate}
                            onChange={handleChange}
                            title="Select Date"
                          />
                        </div>
                        <div className="btn-wrapper">
                          <Button color="primary" className="mr-1 mb-1" disabled={spinnerEnable}>
                            <FontAwesomeIcon icon={faSearch} />
                          </Button>
                        </div>
                      </div>

                    </div>

                  </Col>
                </Row>
                <Nav style={{ flexDirection: "row-reverse", textDecoration: 'underline', cursor: 'pointer' }}>
                  <NavItem style={{ cursor: spinnerEnable ? "not-allowed" : "", opacity: spinnerEnable ? 0.6 : 1 }} onClick={clearFilters}>
                    <NavLink>Clear Filters</NavLink>
                  </NavItem>
                </Nav>
              </AvForm>
              <Row className="schedule-row">
                <Col md={4} lg={5}>
                  <span>
                    {searchPhysicianResults.length > 0 ? <span>Showing {searchPhysicianResults.length
                    } Care Provider </span> : null}
                  </span>
                </Col>
                <Col md={8} lg={7}>
                  <div className="schedule-header">
                    <Row>
                      <Col md={12}>
                        <Carousel
                          ref={sliderCalenRef}
                          itemsToShow={sliderCount}
                          pagination={false}
                          renderArrow={({ type, onClick }) => {
                            const pointer = type === consts.PREV ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronRight} />;
                            return <button className="arrow-btn" onClick={onClick}>{pointer}</button>
                          }}
                          enableSwipe={false}
                          disableArrowsOnEnd={true}
                          enableMouseSwipe={false}
                          preventDefaultTouchmoveEvent={true}
                          onNextStart={onNextHandler}
                          onPrevStart={onPrevHandler}
                        >
                          {carouselDays.map(dayCol => {
                            return (
                              <div key={dayCol.date} className="day-slot">
                                <span>{dayCol.day}</span>
                                <span className="slot-date">{dayCol.date}</span>
                              </div>
                            )
                          })}
                        </Carousel>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={12}>
                  <hr className="divider-line" />
                </Col>
              </Row>
              <div className="profile-content-wrapper physician-list">
                {
                  spinnerEnable ? (
                    <div className="skeleton">
                      <div className="skeleton-wrapper">
                        <div className="skeleton-wrapper-inner">
                          <div className="skeleton-wrapper-body">
                            <div className="skeleton-avatar"></div>
                            <div className="skeleton-author"></div>
                            <div className="skeleton-label"></div>
                            <div className="skeleton-content-1"></div>
                            <div className="skeleton-content-2"></div>
                            <div className="skeleton-content-3"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null
                }
                {
                  searchPhysicianResults.map(physician => {
                    let speciality = ""
                    let totalPayable = ""
                    let currencyCode = ""
                    let duration = ""
                    physician.physicianData.appointmentFee.map((item) => { //eslint-disable-line
                      if (item.specialityID === searchedSpeciality) {
                        speciality = item.specialityText
                        totalPayable = item.totalPayable
                        currencyCode = item.currency_code
                        duration = item.duration
                      }
                    })

                    return (
                      <React.Fragment key={physician.id}>
                        <Row noGutters className="profile-row">
                          <Col lg={5} md={4}>
                            <div className={styles.profileWrapper}>
                              <div className={styles.imageWrapper}>
                                <img
                                  src={physician.profileImageURL}
                                  onError={(e) => { e.target.onerror = null; e.target.src = avatar }}
                                  width="90"
                                  height="90"
                                  className="rounded-circle object-cover"
                                  alt="Avatar"
                                />
                              </div>
                              <div className={styles.profileContent}>
                                <div className={styles.profileData}>
                                  <h6>{physician.title ? physician.title + " " : null}{physician.name}<span className="detailsProfContentStarRating"><div>4.5<FontAwesomeIcon icon={faStar} className="iconLeftSpacing" /></div></span></h6>
                                  <h5>{physician.physicianData["0"].gender}</h5>
                                  <h5>{physician.physicianData["0"].credentials}</h5>
                                  <h6>{speciality}</h6>
                                  <p className="legend-row">
                                    <span className="legend-label">Consultation Fee</span>
                                    <span className="legend-val">{getSymbolFromCurrency(currencyCode)}{totalPayable} for {duration} mins</span>
                                  </p>
                                  {/* <p>{physician.email}</p> */}
                                </div>
                              </div>
                            </div>
                            <div className="link-sections">
                              <ProfileModal data={physician} speciality={speciality} duration={duration} searchedSpeciality={searchedSpeciality} />
                            </div>
                          </Col>
                          <Col lg={7} md={8} className="slider-container">
                            <div className="slide-wrap">
                              <div className="pat-time-row">
                                {timeslot && timeslot[physician.id] ?
                                  <Carousel
                                    ref={sliderEleRef}
                                    itemsToShow={sliderCount}
                                    pagination={false}
                                    // showArrows={false}
                                    enableSwipe={false}
                                    renderArrow={({ type, onClick }) => {
                                      const pointer = type === consts.PREV ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronRight} />;
                                      const btnClassName = type === consts.PREV ? 'leftButton' : 'rightButton';
                                      return <button className={btnClassName} style={{ display: 'none' }} onClick={onClick}>{pointer}</button>
                                    }}
                                  >
                                    {
                                      carouselDays.map((value) => {
                                        return (
                                          <React.Fragment key={value.formattedDate}>
                                            <div className="am-block">
                                              <ul className="pat-time-slot" ref={sliderEleRef}>
                                                {timeslot && timeslot[physician.id][value.formattedDate] ?
                                                  timeslot[physician.id][value.formattedDate].map((slots, index) => { // eslint-disable-line
                                                    if (slots.localTime.search("AM") !== -1) {
                                                      return (
                                                        <li className="pat-time-slot__items" key={index}>
                                                          <span className={`${slots.status === "Available" ? "pat-time-slot__item" : "pat-time-slot__item---empty"}`} onClick={() => onSlotCheckout(physician.id, slots, physician)}>{slots.localTime}</span>
                                                        </li>
                                                      )
                                                    }
                                                  }) :
                                                  <li className="pat-time-slot__items">
                                                    <span className="pat-time-slot__item---empty">No Slots</span>
                                                  </li>
                                                }
                                              </ul>
                                            </div>
                                            <div className="pm-block">
                                              <ul className="pat-time-slot" ref={sliderEleRef}>
                                                {timeslot && timeslot[physician.id][value.formattedDate] ?
                                                  timeslot[physician.id][value.formattedDate].map((slots, index) => { // eslint-disable-line
                                                    if (slots.localTime.search("PM") !== -1) {
                                                      return (
                                                        <li className="pat-time-slot__items" key={index}>
                                                          <span className={`${slots.status === "Available" ? "pat-time-slot__item" : "pat-time-slot__item---empty"}`} onClick={() => onSlotCheckout(physician.id, slots, physician)}>{slots.localTime}</span>
                                                        </li>
                                                      )
                                                    }
                                                  }) :
                                                  <li className="pat-time-slot__items">
                                                    <span className="pat-time-slot__item---empty">No Slots</span>
                                                  </li>
                                                }
                                              </ul>
                                            </div>
                                          </React.Fragment>
                                        )
                                      })
                                    }
                                  </Carousel>
                                  : null
                                }
                              </div>
                              <div className="show-more-wrapper">
                                <a // eslint-disable-line
                                  className="show-more-btn"
                                  onClick={showContentToggle}
                                >Show more</a>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    );
                  })
                }
                {
                  !searchTriggered ? (
                    <Row noGutters className="profile-row">
                      <Col lg={12} md={12}>
                        <div className="h3 bg-light empty-content">Please choose a speciality and date to list the available physicians</div>
                      </Col>
                    </Row>
                  ) : null
                }
                {!spinnerEnable && searchTriggered && searchPhysicianResults.length === 0 ? (
                  <Row noGutters className="profile-row">
                    <Col lg={12} md={12}>
                      No Care Providers found
                    </Col>
                  </Row>
                ) : null
                }
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <CountryConfirmationModal isOpen={isOpen} id={id} slot={slot} physician={physician} onAccept={proceedToCheckout} onReject={setIsOpen} />
    </PageLayout>
  );
}


export default Booking;
