import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, ModalBody, Row, Col, Spinner, Alert } from 'reactstrap';//eslint-disable-line
import { cookieService } from '../../services/cookieService';
import { Link, useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import OtpInput from "react-otp-input";
import { GlobalState } from '../../context/ContextApi';
import { useTranslation } from "react-i18next";

const MobileNumberVerificationModal = (props) => {
  // const { value: otpNumber, bind: bindOTPNumber} = useInput("");
  const { Details, setDetails } = useContext(GlobalState);
  const { mobileVerificationStatus, setMobileVerificationModalStatus } = props;



  const toggle = () => {
    setMobileVerificationModalStatus(!mobileVerificationStatus);
    // window.location.reload();
  }

  const [sid, setSid] = useState("");//eslint-disable-line
  // const [sidState, setSidState] = useState(true);
  const [otpNumber, setOtpNumber] = useState("");
  let authToken = cookieService.get("token");
  const [spinnerEnable, setSpinnerEnable] = useState(false);
  const [resendOtpState, setResendOtp] = useState(false);//eslint-disable-line
  const [verifyOtpButtonState, setVerifyOtpButtonState] = useState(true);
  const [resendOtpButtonState, setResendOtpButtonState] = useState(true);
  const [attemptsDiv, setAttemptsDiv] = useState(false);
  const [attemptsCount, setAttemptsCount] = useState(5);
  const [otpField, setOtpField] = useState(true);
  const [verificationTimeoutWarning, setVerificationTimeoutWarning] = useState(false);
  const [wrongOtpWarning, setWrongOtpWarning] = useState(false);
  const [resendOtpLink, setResendOtpLink] = useState(false);

  let history = useHistory();

  let phoneNumber = `${Details.phone}`;
  let phoneNumberAfterMasking = phoneNumber.replace(/\d(?=\d\d\d\d)/g, "*");
  let mobileNumber = Details ? `${Details.countryCode}${phoneNumberAfterMasking}` : ""

  var date = new Date();
  // toastr.options = {
  //     timeO
  // }

  const handleChange = (otp) => {
    setOtpNumber(otp);

  }

  const generateOtp = () => {
    const requestData = {
      message_text: "equipo mobile"
    }
    setSpinnerEnable(true);
    axios
      .post("/generate-otp", requestData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        setResendOtpLink(true);
        date.setSeconds(600);
        setSid(response.data.data.sid);
        cookieService.set("twilio_otp_sid", response.data.data.sid, {
          // path: "/",
          expires: date,
        })
        setSpinnerEnable(false);
        toastr.success("Success", response.data.title);

      })
      .catch(function (error) {
        setSpinnerEnable(false);
        // console.log(error.response.data.title);
        toastr.error(
          "Error",
          error.response.data.title
        );
        toggle();


      });

  }

  const verifyOTP = (event, errors, values) => {
    setResendOtpLink(false);
    setVerifyOtpButtonState(false);
    setSpinnerEnable(true);
    const request = {
      sid: cookieService.get('twilio_otp_sid'),
      otp: otpNumber,

    };

    axios
      .post("/verify-otp", request,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        setResendOtpLink(true);
        // setSpinnerEnable(false);
        if (response.data.data.message === "approved") {
          // setVerifyOtpButtonState(false);
          setSpinnerEnable(false);
          cookieService.remove("twilio_otp_sid");
          let mobileVerificationStatus = (response.data.data.isMobileNoVerified) ? response.data.data.isMobileNoVerified : "";
          if (mobileVerificationStatus) {
            setDetails("");
            //   setTimeout(() => {

            //   },1000);
            toastr.success("Success", response.data.title);
            // window.location = "/settings/profile";
            history.push('/settings/profile');
            return;

          }

          // setTimeout(() => {

          //       },2000);
          toastr.success("Success", response.data.title);
          // window.location = "/";
          history.push('/');

        }
        if (response.data.data.message === "pending") {
          if (attemptsCount > 1) {
            setAttemptsCount(attemptsCount - 1);
            setAttemptsDiv(true);
            setVerifyOtpButtonState(true);
          }
          else {
            setAttemptsDiv(false);
            setVerifyOtpButtonState(false);
            setOtpNumber("");
            setOtpField(false);
            setWrongOtpWarning(true);
            setResendOtpLink(false);
          }
          setSpinnerEnable(false);
          toastr.error('Error', response.data.title);
          // setVerifyOtpButtonState(true);
          // console.log(response.data.title);
        }

        // if(response.data.status === 500) {
        //     // setVerifyOtpButtonState(false);
        //     console.log(response.data.status);
        // }


      })
      .catch(function (error) {
        setSpinnerEnable(true);
        setVerifyOtpButtonState(false);
        setSpinnerEnable(false);
        if (error.response.data.status === 404) {
          setVerificationTimeoutWarning(true);
          setOtpField(false);
          setAttemptsDiv(false);
          // toastr.error(
          //     "Error",
          //    '404'
          //   );
        }
        if (error.response.data.status === 429) {
          toastr.error(
            "Error",
            'There is an issue.Please try again after sometime'
          );
        }

      });


  }

  const resendOtp = () => {
    setResendOtpLink(false);
    setResendOtpButtonState(false);
    setSpinnerEnable(true);
    setVerifyOtpButtonState(false);
    setAttemptsDiv(false);
    setAttemptsCount(5);
    const requestData = {
      message_text: "equipo mobile"
    }
    axios
      .post("/generate-otp", requestData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        setResendOtpLink(true);
        setVerificationTimeoutWarning(false);
        setWrongOtpWarning(false);
        setSpinnerEnable(false);
        setVerifyOtpButtonState(true);
        cookieService.remove("twilio_otp_sid");
        var date = new Date();
        date.setSeconds(600);
        setSid(response.data.data.sid);
        cookieService.set("twilio_otp_sid", response.data.data.sid, {
          // path: "/",
          expires: date,
        });
        setResendOtp(false);
        setOtpNumber("");
        toastr.success("Success", 'A new OTP has been sent to your mobile number');
        setOtpField(true);
        setResendOtpButtonState(true);

      })
      .catch(function (error) {
        toastr.error(
          "Error",
          error.response.data.title
        );
        setSpinnerEnable(false);
        setVerifyOtpButtonState(true);
        setMobileVerificationModalStatus(false);
      });

  }
  const otpInputStyle = {
    width: '7vh',
    height: '7vh',
    margin: '0 1vh',
    fontSize: '5vh',
    borderRadius: '4px',
    border: '1px solid rgba(0,0,0,0.3)'


  }


  const { t } = useTranslation();//eslint-disable-line
  useEffect(() => { if (mobileVerificationStatus) { generateOtp(); } }, [mobileVerificationStatus]);//eslint-disable-line


  return (
    <div>
      <Modal isOpen={mobileVerificationStatus} centered size="md">
        <h3 className="ModalHeader mt-3">Mobile Number Verification</h3>
        <div
          className="vital-modal-close"
          onClick={() => toggle()}
        >
          <FontAwesomeIcon size={"sm"} icon={faTimesCircle} />
        </div>
        <div className="modal-border mb-3"></div>
        <ModalBody>
          {/* <Card>
            <CardBody className="otp-card-body"> */}
          <div>
            {/* <div className="text-center mb-3">
                  <img
                    src={logo}
                    alt={t("altTextAppLogo")}
                    className="modal-otp-img-fluid"

                  />
                </div> */}
            {otpField ?
              <div>
                <Row>
                  <Col md="12" className="text-center">

                    <p>Your mobile number is not verified</p>
                    <p>Enter the 6-digit OTP number we have sent to {mobileNumber}</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ size: 3, offset: 2 }}>
                    <OtpInput
                      value={otpNumber}
                      onChange={(e) => { handleChange(e) }}
                      numInputs={6}
                      isInputNum={true}
                      separator={<span>-</span>}
                      inputStyle={otpInputStyle}
                    />
                  </Col>
                </Row>
              </div>
              : ""}
            {wrongOtpWarning ?
              <div className="text-center">
                <p>You have entered incorrect OTP multiple times</p>
                <p>Please click the <b>Resend OTP</b> button below to generate a new OTP.</p>
                {resendOtpButtonState ?
                  <Button
                    color="primary"
                    onClick={() => resendOtp()}
                    className="text-center"
                  >
                    Resend OTP
                  </Button> : ""}
              </div> : ""}

            {verificationTimeoutWarning ?
              <div className="text-center">
                <p>The OTP has expired</p>
                <p>Please click the <b>Resend OTP</b> button below to generate a new OTP.</p>
                <Button
                  color="primary"
                  onClick={() => resendOtp()}
                  className="text-center"
                >
                  Resend OTP
                </Button>
              </div> : ""}
            <br />
            {spinnerEnable && (
              <div className="spinner-wrapper">
                <Spinner
                  color="dark"
                  type="grow"
                  className="mr-2"
                  size="sm"
                />
                <Spinner
                  color="dark"
                  type="grow"
                  className="mr-2"
                  size="sm"
                />
                <Spinner
                  color="dark"
                  type="grow"
                  className="mr-2"
                  size="sm"
                />

              </div>
            )}

            {verifyOtpButtonState ?
              <div className="text-center">
                <Button
                  color="primary"
                  onClick={() => verifyOTP()}
                  className="text-center mr-2"
                  disabled={(otpNumber.length === 6) ? false : true}
                >
                  Verify OTP
                </Button>
                <Button className="outline-primary" color="secondary" onClick={() => toggle()}>Later</Button>
              </div>
              : ""}




            {attemptsDiv ?
              <div className="text-center mt-4">
                <small>You have {attemptsCount} attempt(s) left.</small>
              </div> : ""}
          </div>
        </ModalBody>
        {resendOtpLink ?
          <div className="text-center mt-2 mb-4">
            <Link onClick={() => resendOtp()}>Resend OTP</Link>
          </div> : ""}

      </Modal>
    </div>
  );
};

export default MobileNumberVerificationModal;